import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  name: '',
  trade_name: '',
  id_number: '',
  contact_name: '',
  phone_number: '',
  email: '',
  has_credit: null,
  type_ids: []
}

const schema = yup.object().shape({
  name: yup.string().required(validations.requiredField.code),
  trade_name: yup.string().nullable(),
  id_number: yup.string().nullable(),
  contact_name: yup.string().required(validations.requiredField.code),
  phone_number: yup.string().required(validations.requiredField.code),
  email: yup.string().email().required(validations.requiredField.code),
  has_credit: yup.bool().nullable(),
  type_ids: yup
    .array()
    .of(yup.number().required(validations.requiredField.code))
})

export default {
  initialValues,
  schema
}
