import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

import { typesUtils } from 'utils'

const { isImport, isExport } = typesUtils

const singleObject = {
  location_id: '',
  transportation_type: '',
  container_number: '',
  container_status: '',
  container_iso_type: '',
  mgw_kilograms: '',
  tare_kilograms: '',
  max_payload_kilograms: '',
  volume_cbm: '',
  container_year: '',
  container_condition: '',
  is_damage: '',
  booking_number: '',
  stack: '',
  driver_name: '',
  driver_id: '',
  plate_id: '',
  liner_id: '',
  shipper_id: '',
  client_id: '',
  consignee_id: '',
  comments: ''
}

const initialValues = {
  inventory: Array.apply(null, Array(1)).map(() => singleObject)
}

const schema = yup.object().shape({
  inventory: yup.array().of(
    yup.object().shape({
      location_id: yup.string().required(validations.requiredField.code),
      transportation_type: yup
        .string()
        .required(validations.requiredField.code),
      container_number: yup.string().required(validations.requiredField.code),
      container_status: yup.string().required(validations.requiredField.code),
      client_id: yup.string().required(validations.requiredField.code),
      container_iso_type: yup.string(),
      mgw_kilograms: yup.number(),
      tare_kilograms: yup.number(),
      max_payload_kilograms: yup.number(),
      volume_cbm: yup.number(),
      container_year: yup.number(),
      container_condition: yup.string(),
      is_damage: yup.boolean(),
      booking_number: yup.string(),
      stack: yup.string(),
      driver_name: yup.string(),
      driver_id: yup.string(),
      plate_id: yup.string(),
      liner_id: yup.string().nullable(),
      shipper_id: yup
        .string()
        .nullable()
        .when(['transportation_type'], {
          is: val => isExport(val),

          then: yup.string().nullable()
        }),
      consignee_id: yup
        .string()
        .nullable()
        .when(['transportation_type'], {
          is: val => isImport(val),
          then: yup.string().nullable()
        }),
      comments: yup.string()
    })
  )
})

export default {
  initialValues,
  singleObject,
  schema
}
