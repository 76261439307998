import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Box, Tooltip, IconButton, FormHelperText } from '@mui/material'
import {
  AddAPhoto as AddAPhotoIcon,
  HighlightOff as HighlightOffIcon
} from '@mui/icons-material'
import Skeleton from '@mui/material/Skeleton'
import { useTranslation } from 'react-i18next'

import { BaseTextField, FilePreview } from 'components'

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  flex: 1,
  width: '100%',
  height: 150
}))

function FileUploadTextField({
  onFileChange,
  file,
  spacing = false,
  loading = false,
  allowDelete = false,
  onDelete = () => {},
  name = 'FileUploadTextField',
  fileError,
  ...props
}) {
  const { t } = useTranslation()

  return (
    <Box
      width='100%'
      borderBottom={1}
      borderBottomColor='grey.300'
      borderBottomWidth={1}
    >
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        width='100%'
        my={1}
      >
        <BaseTextField name={name} loading={loading} {...props} />

        <input
          id={name}
          type='file'
          accept='image/*'
          onChange={onFileChange}
          style={{ display: 'none' }}
        />

        <label htmlFor={name}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            px={2}
          >
            <Tooltip title={t('takePhoto')}>
              <AddAPhotoIcon />
            </Tooltip>

            {allowDelete && (
              <Tooltip title={t('delete')}>
                <IconButton onClick={onDelete} size='large'>
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </label>
      </Box>

      {file && !loading && <FilePreview value={file} spacing={spacing} />}

      {loading && <StyledSkeleton />}

      {fileError ? (
        <FormHelperText error={true} variant='outlined' margin={undefined}>
          {fileError}
        </FormHelperText>
      ) : null}
    </Box>
  )
}

FileUploadTextField.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  file: PropTypes.string,
  spacing: PropTypes.bool,
  loading: PropTypes.bool,
  allowDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  name: PropTypes.string,
  fileError: PropTypes.string,
  props: PropTypes.object
}

export default FileUploadTextField
