import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import { ipfsConfig } from 'config'

function FileUpload({ value, spacing = false }) {
  if (!value) {
    return null
  }

  return (
    <Box
      display='flex'
      flex={1}
      width='100%'
      height={150}
      alignItems='center'
      justifyContent='center'
      mb={spacing ? 2 : 0}
    >
      <img src={`${ipfsConfig.url}${value}`} alt={value} height='100%' />
    </Box>
  )
}

FileUpload.propTypes = {
  value: PropTypes.string,
  spacing: PropTypes.bool
}

export default FileUpload
