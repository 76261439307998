import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

import { constants } from 'config'

const {
  FIELD_TYPES: { NUMBER, STRING, BOOLEAN, SELECT, PHOTO, MULTI_SELECT },
  DEFAULT_FIELDS: {
    CONTAINER_ISO_TYPE,
    MGW_KILOGRAMS,
    MAX_PAYLOAD_KILOGRAMS,
    TARE_KILOGRAMS,
    CARGO_WEIGHT_KILOGRAMS,
    VOLUME_CBM,
    CONTAINER_YEAR,
    CONTAINER_EMPTY_PHOTO,
    SEAL_PHOTOS,
    LINER_ID,
    SHIPPER_ID,
    CONSIGNEE_ID,
    BOOKING_NUMBER,
    DRIVER_ID_PHOTO,
    DRIVER_NAME,
    DRIVER_ID,
    DRIVER_PHOTO,
    PLATE_PHOTO,
    PLATE_ID,
    IS_DAMAGE,
    DAMAGE_PHOTOS,
    CONTAINER_CONDITION,
    STACK,
    COMMENTS,
    CONTAINER_CONDITION_PHOTOS
  }
} = constants

const setRequired = (yupType, isRequired, isPhoto = false) => {
  return isRequired
    ? yupType.required(
        isPhoto
          ? validations.requiredPhoto.code
          : validations.requiredField.code
      )
    : yupType.nullable()
}

const buildResponse = (schema, initialValue) => {
  return {
    schema,
    initialValue
  }
}

const schemasFactory = ({ isDefault, name, type, isRequired }) => {
  if (isDefault) {
    switch (name) {
      case CONTAINER_ISO_TYPE:
      case BOOKING_NUMBER:
      case DRIVER_NAME:
      case DRIVER_ID:
      case PLATE_ID:
      case STACK:
      case COMMENTS:
      case LINER_ID:
      case SHIPPER_ID:
      case CONSIGNEE_ID:
      case CONTAINER_CONDITION:
        return buildResponse(setRequired(yup.string(), isRequired), '')

      case MGW_KILOGRAMS:
      case MAX_PAYLOAD_KILOGRAMS:
      case TARE_KILOGRAMS:
      case CARGO_WEIGHT_KILOGRAMS:
      case VOLUME_CBM:
      case CONTAINER_YEAR:
        return buildResponse(setRequired(yup.number(), isRequired), '')

      case CONTAINER_EMPTY_PHOTO:
      case DRIVER_ID_PHOTO:
      case DRIVER_PHOTO:
      case PLATE_PHOTO:
        return buildResponse(
          setRequired(
            yup.object().shape({
              hash: setRequired(yup.string(), isRequired, true),
              type: setRequired(yup.string(), isRequired)
            }),
            isRequired
          ),
          ''
        )

      case SEAL_PHOTOS:
        return buildResponse(
          setRequired(
            yup.array().of(
              setRequired(
                yup.object().shape({
                  hash: setRequired(yup.string(), isRequired, true),
                  type: setRequired(yup.string(), isRequired),
                  value: setRequired(yup.string(), isRequired),
                  seal_type: setRequired(yup.string(), isRequired)
                }),
                isRequired
              )
            ),
            isRequired
          ),
          []
        )

      case IS_DAMAGE:
        return buildResponse(setRequired(yup.boolean(), isRequired), '')

      case DAMAGE_PHOTOS:
        return buildResponse(
          yup.array().when(['is_damage'], {
            is: val => val,
            then: setRequired(
              yup.array().of(
                setRequired(
                  yup.object().shape({
                    damage_type: setRequired(yup.string(), true),
                    hash: setRequired(yup.string(), true, true),
                    type: setRequired(yup.string(), true)
                  }),
                  isRequired
                )
              ),
              true
            )
          }),
          []
        )

      case CONTAINER_CONDITION_PHOTOS:
        return buildResponse(
          setRequired(
            yup.array().of(
              setRequired(
                yup.object().shape({
                  hash: setRequired(yup.string(), isRequired, true),
                  type: setRequired(yup.string(), isRequired)
                }),
                isRequired
              )
            ),
            isRequired
          ),
          []
        )

      default:
        return 'DEFAULT'
    }
  }

  switch (type) {
    case NUMBER:
      return buildResponse(setRequired(yup.number(), isRequired), '')

    case SELECT:
    case STRING:
      return buildResponse(setRequired(yup.string(), isRequired), '')

    case BOOLEAN:
      return buildResponse(setRequired(yup.boolean(), isRequired), '')

    case MULTI_SELECT:
      return buildResponse(
        setRequired(
          yup.array().of(setRequired(yup.string(), isRequired)),
          isRequired
        ),
        []
      )

    case PHOTO:
      return buildResponse(
        setRequired(
          yup.object().shape({
            hash: setRequired(yup.string(), isRequired, true),
            type: setRequired(yup.string(), isRequired)
          }),
          isRequired
        ),
        ''
      )

    default:
      return 'DEFAULT'
  }
}

export default {
  schemasFactory
}
