import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  id: '',
  name: '',
  type: '',
  un_locode: '',
  timezone_id: '',
  geolocation: '',
  address: '',
  primary_color: '',
  logo: '',
  report_width: '',
  report_height: '',
  report_orientation: '',
  report_font_size: '',
  initial_consecutive_folio_number: '',
  has_vpn: ''
}

const schema = yup.object().shape({
  id: yup.number().required(validations.requiredField.code),
  name: yup.string().required(validations.requiredField.code),
  type: yup.string().required(validations.requiredField.code),
  un_locode: yup.string().required(validations.requiredField.code),
  timezone_id: yup.string().required(validations.requiredField.code),
  geolocation: yup
    .object()
    .shape({
      lat: yup.number().required(validations.requiredField.code),
      lng: yup.number().required(validations.requiredField.code)
    })
    .required(validations.requiredField.code),
  address: yup.string().nullable(),
  primary_color: yup.string().nullable(),
  logo: yup.string().nullable(),
  report_width: yup.string().nullable(),
  report_height: yup.string().nullable(),
  report_orientation: yup.string().nullable(),
  report_font_size: yup.string().nullable(),
  initial_consecutive_folio_number: yup.string().nullable(),
  has_vpn: yup.bool().nullable()
})

export default {
  initialValues,
  schema
}
