import React from 'react'
import { useTheme } from '@mui/material/styles'
import { InputAdornment, MenuItem } from '@mui/material'
import { Language as LanguageIcon } from '@mui/icons-material'
import { languages } from '@edenia/smartgate-i18n-module'
import { useTranslation } from 'react-i18next'

import { BaseTextField } from 'components'

function LanguageSelect() {
  const { t, i18n } = useTranslation()
  const theme = useTheme()

  return (
    <BaseTextField
      white
      select
      fullWidth={false}
      label={t('language')}
      onChange={ev => {
        i18n.changeLanguage(ev.target.value)
      }}
      value={i18n.language.split('-')[0]}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <LanguageIcon sx={{ color: theme.palette.common.white }} />
          </InputAdornment>
        )
      }}
    >
      {languages.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </BaseTextField>
  )
}

export default LanguageSelect
