import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  emailOrUsername: '',
  password: ''
}

const schema = yup.object().shape({
  emailOrUsername: yup.string().required(validations.requiredField.code),
  password: yup.string().required(validations.requiredField.code)
})

export default {
  initialValues,
  schema
}
