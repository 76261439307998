import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  id: '',
  name: '',
  client_id: '',
  release_party: '',
  expiration: new Date(),
  location_id: '',
  to_location_code: '',
  un_locode: '',
  transactions: []
}

const schema = yup.object().shape({
  id: yup.number().required(validations.requiredField.code),
  name: yup.string().required(validations.requiredField.code),
  client_id: yup.string().required(validations.requiredField.code),
  release_party: yup.string().required(validations.requiredField.code),
  expiration: yup.string().required(validations.requiredField.code),
  location_id: yup.string().required(validations.requiredField.code),
  to_location_code: yup.string(),
  un_locode: yup.string(),
  transactions: yup.array().of(
    yup.object().shape({
      id: yup.number().required(validations.requiredField.code),
      inventory_item: yup.object().shape({
        id: yup.number().required(validations.requiredField.code)
      })
    })
  )
})

export default {
  initialValues,
  schema
}
