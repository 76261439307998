import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'

import { BaseButton } from 'components'
import { constants } from 'config'

const { DEFAULT_FIELDS } = constants

function DefaultFieldsMenu({ onClick, steps }) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = useCallback(event => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <BaseButton
        fullWidth={false}
        color='primary'
        onClick={handleOpen}
        startIcon={<AddIcon />}
      >
        {t('addBaseField')}
      </BaseButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 400
          }
        }}
      >
        {Object.keys(DEFAULT_FIELDS).map(key => {
          const name = DEFAULT_FIELDS[key]
          const disabled = !!steps.find(
            ({ fields }) =>
              fields &&
              fields.find(field => {
                if (!field.isDefault) {
                  return false
                }

                return field.name === name
              })
          )

          return (
            <MenuItem
              key={name}
              disabled={disabled}
              onClick={() => {
                onClick(name)
                setAnchorEl(null)
              }}
            >
              {t(name)}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

DefaultFieldsMenu.propTypes = {
  onClick: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object)
}

export default DefaultFieldsMenu
