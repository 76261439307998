const diff = (array1, array2) => {
  const result = []

  for (let i = 0; i < array1.length; i++) {
    const original = array1[i]
    let exist = false
    for (let j = 0; j < array2.length; j++) {
      const current = array2[j]
      if (original === current) {
        exist = true
      }
    }

    if (!exist) {
      result.push(original)
    }
  }

  return result
}

module.exports = {
  diff
}
