const sortByPosition = template =>
  Object.entries(template)
    .sort(([, a], [, b]) => a.position - b.position)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

const parseDbTemplateToInitialFormData = dbTemplate => {
  const steps = Object.keys(sortByPosition(dbTemplate.template)).map(key => {
    const step = dbTemplate.template[key]
    const { name, fields } = step
    const fieldsArray = Object.keys(sortByPosition(fields)).map(key => {
      return {
        ...fields[key],
        showInReport: fields[key]?.showInReport || false
      }
    })

    return {
      name,
      fields: fieldsArray
    }
  })

  return {
    name: dbTemplate.name,
    type: dbTemplate.transaction_template_type,
    is_release_required: dbTemplate?.is_release_required,
    location_id: dbTemplate.location_id,
    client_id: dbTemplate.client_id,
    client: dbTemplate.client,
    steps
  }
}

const isPhoto = customField =>
  typeof customField === 'object' && customField?.hash

export default {
  sortByPosition,
  parseDbTemplateToInitialFormData,
  isPhoto
}
