import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@mui/material/styles'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MomentAdapter from '@mui/lab/AdapterMoment'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'

import { muiConfig } from 'config'
import { useThemeType } from 'context/theme'

function Providers({ children }) {
  const { themeType } = useThemeType()
  const theme = muiConfig.getTheme(themeType)

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateLibInstance={moment}
        dateAdapter={MomentAdapter}
      >
        {children}
      </LocalizationProvider>
    </ThemeProvider>
  )
}

Providers.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired
}

export default Providers
