import { typesUtils, formatUtils } from 'utils'
import { constants } from 'config'
import { inventorySchema } from 'schemas'

const { isImport, isExport, isEmpty, isGateIn: getIsGateIn } = typesUtils
const { singleObject } = inventorySchema
const {
  ENUM_DATA: { TRANSACTION_TYPES, TEMPLATE_TYPES }
} = constants

const initialValuesFormat = (
  initialValues,
  userId,
  locationId,
  transportationType
) => ({
  inventory: initialValues.inventory.map(initialValue => ({
    ...initialValue,
    user_id: userId,
    location_id: locationId,
    transportation_type: transportationType
  }))
})

const singleObjectFormat = (
  initialValue,
  userId,
  locationId,
  transportationType
) => ({
  ...initialValue,
  user_id: userId,
  location_id: locationId,
  transportation_type: transportationType
})

const importFormat = (
  inventory,
  userId,
  locationId,
  transportationType,
  organizations
) => {
  return {
    inventory: inventory.slice(0, 30).map(i => ({
      ...singleObject,
      ...i,
      container_number: i?.container_number?.toUpperCase(),
      container_status: i?.container_status,
      container_iso_type: i?.container_iso_type,
      mgw_kilograms: i?.mgw_kilograms,
      tare_kilograms: i?.tare_kilograms,
      max_payload_kilograms: i?.max_payload_kilograms,
      volume_cbm: i?.volume_cbm,
      container_year: i?.container_year,
      container_condition: i?.container_condition?.toString(),
      is_damage: !!i?.is_damage,
      booking_number: i?.booking_number?.toString(),
      stack: i?.stack?.toString(),
      driver_name: i?.driver_name?.toString(),
      driver_id: i?.driver_id?.toString(),
      plate_id: i?.plate_id?.toString(),
      liner_id: organizations.liners.find(({ id }) => id === i?.liner_id)
        ? Number(i?.liner_id)
        : null,
      shipper_id:
        isExport(transportationType) &&
        organizations.shippers.find(({ id }) => id === i?.shipper_id)
          ? Number(i?.shipper_id)
          : null,
      client_id: organizations.clients.find(({ id }) => id === i?.client_id)
        ? Number(i?.client_id)
        : null,
      consignee_id:
        isImport(transportationType) &&
        organizations.consignees.find(({ id }) => id === i?.consignee_id)
          ? Number(i?.consignee_id)
          : null,
      comments: i?.comments || '',
      user_id: Number(userId),
      location_id: Number(locationId),
      transportation_type: transportationType
    }))
  }
}

const getTemplateType = (type, status, transportationType) => {
  const isGateIn = getIsGateIn(type)

  if (isGateIn) {
    if (isEmpty(status)) {
      if (isImport(transportationType)) {
        return TEMPLATE_TYPES.GATE_IN_EMPTY_IMPORT
      } else {
        return TEMPLATE_TYPES.GATE_IN_EMPTY_EXPORT
      }
    } else {
      if (isImport(transportationType)) {
        return TEMPLATE_TYPES.GATE_IN_LOADED_IMPORT
      } else {
        return TEMPLATE_TYPES.GATE_IN_LOADED_EXPORT
      }
    }
  } else {
    if (isEmpty(status)) {
      if (isImport(transportationType)) {
        return TEMPLATE_TYPES.GATE_OUT_EMPTY_IMPORT
      } else {
        return TEMPLATE_TYPES.GATE_OUT_EMPTY_EXPORT
      }
    } else {
      if (isImport(transportationType)) {
        return TEMPLATE_TYPES.GATE_OUT_LOADED_IMPORT
      } else {
        return TEMPLATE_TYPES.GATE_OUT_LOADED_EXPORT
      }
    }
  }
}

const getTemplateValue = (templateType, templates) => {
  return templates?.[templateType]?.[0]
}

const createFormat = (values, templates) => {
  const response = values.inventory.map(transaction => {
    const type = isEmpty(transaction.container_status)
      ? TRANSACTION_TYPES.GATE_IN_EMPTY
      : TRANSACTION_TYPES.GATE_IN_LOADED

    const templateType = getTemplateType(
      type,
      transaction.container_status,
      transaction.transportation_type
    )

    const template = getTemplateValue(templateType, templates)

    return {
      template_id: template?.id,
      user_id: transaction.user_id,
      location_id: transaction.location_id,
      transportation_type: transaction.transportation_type,
      container_status: transaction.container_status,
      type,
      container_condition: transaction.container_condition,
      is_damage: transaction.is_damage,
      booking_number: transaction.booking_number,
      stack: transaction.stack,
      driver_name: transaction.driver_name,
      driver_id: transaction.driver_id,
      plate_id: transaction.plate_id,
      liner_id: formatUtils.toNumberOrNull(transaction.liner_id),
      client_id: formatUtils.toNumberOrNull(transaction.client_id),
      shipper_id: isExport(transaction.transportation_type)
        ? formatUtils.toNumberOrNull(transaction.shipper_id)
        : null,
      consignee_id: isImport(transaction.transportation_type)
        ? formatUtils.toNumberOrNull(transaction.consignee_id)
        : null,
      comments: transaction.comments || null,
      inventory_item: {
        data: {
          container_number: transaction.container_number,
          container_iso_type: transaction.container_iso_type,
          mgw_kilograms: transaction.mgw_kilograms,
          tare_kilograms: transaction.tare_kilograms,
          max_payload_kilograms: transaction.max_payload_kilograms,
          volume_cbm: transaction.volume_cbm,
          container_year: transaction.container_year,
          location_id: formatUtils.toNumberOrNull(transaction.location_id)
        }
      }
    }
  })

  return {
    variables: {
      objects: response
    }
  }
}

export default {
  initialValuesFormat,
  singleObjectFormat,
  importFormat,
  createFormat
}
