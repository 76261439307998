import { gql } from '@apollo/client'

const USER_FRAGMENT = gql`
  fragment UserFragment on user {
    id
    first_name
    last_name
    email
    role
    created_at
    user_locations(distinct_on: location_id) {
      id
      location {
        name
        id
        timezone {
          id
          name
          label
        }
      }
    }
    user_organizations(distinct_on: organization_id) {
      id
      organization {
        name
        id
      }
    }
  }
`

const PROFILE_FRAGMENT = gql`
  fragment profileFragment on user {
    id
    first_name
    last_name
    email
    user_name
    password
    role
  }
`

const GET_USERS = gql`
  query users(
    $limit: Int
    $offset: Int
    $orderBy: [user_order_by!]
    $where: user_bool_exp
  ) {
    usersAggregate: user_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    users: user(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`

const GET_ORGANIZATION_VIEWERS_FORM_DATA = gql`
  query organizationViewersFormData {
    organizationViewers: user(
      where: {
        deleted_at: { _is_null: true }
        role: { _eq: organizationViewer }
      }
      order_by: { first_name: asc }
    ) {
      id
      user_name
    }
    organizations: organization(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`

const GET_UPDATE_FORM_DATA = gql`
  query userUpdateFormData {
    locations: location(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
    organizations: organization(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`

const GET_USER = gql`
  query user($id: Int!) {
    user: user_by_pk(id: $id) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`

const GET_PROFILE = gql`
  query profile($id: Int!) {
    user: user_by_pk(id: $id) {
      ...profileFragment
    }
  }
  ${PROFILE_FRAGMENT}
`

const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $id: Int!
    $first_name: String!
    $last_name: String!
    $email: String!
    $user_name: String!
  ) {
    user: update_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        user_name: $user_name
      }
    ) {
      ...profileFragment
    }
  }
  ${PROFILE_FRAGMENT}
`

const UPDATE_USER = gql`
  mutation updateUser(
    $id: Int!
    $role: user_role_enum!
    $deleteOrganizationIds: [Int!]!
    $deleteLocationIds: [Int!]!
    $insertOrganizations: [user_organization_insert_input!]!
    $insertLocations: [user_location_insert_input!]!
  ) {
    delete_user_organization(
      where: {
        user_id: { _eq: $id }
        organization_id: { _in: $deleteOrganizationIds }
      }
    ) {
      affected_rows
    }
    delete_user_location(
      where: { user_id: { _eq: $id }, location_id: { _in: $deleteLocationIds } }
    ) {
      affected_rows
    }
    insert_user_organization(objects: $insertOrganizations) {
      affected_rows
    }
    insert_user_location(objects: $insertLocations) {
      affected_rows
    }
    user: update_user_by_pk(pk_columns: { id: $id }, _set: { role: $role }) {
      id
      first_name
      last_name
      email
      role
      user_locations(distinct_on: location_id) {
        id
        location {
          id
          name
          id
          timezone {
            id
            name
            label
          }
        }
      }
      user_organizations(distinct_on: organization_id) {
        id
        organization {
          id
          name
          id
        }
      }
    }
  }
`

const DELETE_USER = gql`
  mutation deleteUser($id: Int!) {
    user: update_user_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
  }
`

const CREATE_USER = gql`
  mutation insertUser($object: user_insert_input!) {
    user: insert_user_one(object: $object) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`

const CREATE_USER_ORGANIZATION = gql`
  mutation insertUserOrganization(
    $objects: [user_organization_insert_input!]!
  ) {
    user: insert_user_organization(objects: $objects) {
      returning {
        id
        user_id
        organization_id
      }
    }
  }
`

const GET_FORM_DATA = gql`
  query userFormData {
    locations: location(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`

const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $currentPassword: String!
    $newPassword: String!
    $newPasswordConfirmation: String!
  ) {
    change_password(
      passwords: {
        currentPassword: $currentPassword
        newPassword: $newPassword
        newPasswordConfirmation: $newPasswordConfirmation
      }
    ) {
      id
    }
  }
`

export default {
  GET_USERS,
  GET_USER,
  GET_PROFILE,
  UPDATE_PROFILE,
  GET_ORGANIZATION_VIEWERS_FORM_DATA,
  GET_UPDATE_FORM_DATA,
  UPDATE_USER,
  DELETE_USER,
  CREATE_USER,
  CREATE_USER_ORGANIZATION,
  GET_FORM_DATA,
  CHANGE_PASSWORD
}
