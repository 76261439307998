import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  user_name: '',
  password: '',
  role: '',
  location_ids: []
}

const schema = yup.object().shape({
  first_name: yup.string().required(validations.requiredField.code),
  last_name: yup.string().required(validations.requiredField.code),
  email: yup.string().email().required(validations.requiredField.code),
  user_name: yup.string().required(validations.requiredField.code),
  password: yup
    .string()
    .required(validations.requiredField.code)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      'passwordRequirements'
    ),
  role: yup.string().required(validations.requiredField.code),
  location_ids: yup
    .array()
    .of(yup.number().required(validations.requiredField.code))
})

export default {
  initialValues,
  schema
}
