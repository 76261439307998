const createFormat = ({
  name,
  address,
  port,
  username,
  password,
  location_id
}) => {
  return {
    variables: {
      object: {
        name,
        address,
        port,
        username,
        password,
        location_id
      }
    }
  }
}

const updateFormat = ({
  id,
  name,
  address,
  port,
  username,
  password,
  location_id
}) => {
  return {
    variables: {
      id,
      name,
      address,
      port,
      username,
      password,
      location_id
    }
  }
}

const deleteFormat = id => {
  return {
    variables: {
      id
    }
  }
}

export default {
  createFormat,
  updateFormat,
  deleteFormat
}
