import login from './login'
import createOrganizationViewer from './createOrganizationViewer'
import resetPassword from './resetPassword'
import forgotPassword from './forgotPassword'

const schemas = {
  login,
  createOrganizationViewer,
  resetPassword,
  forgotPassword
}

export default schemas
