import React from 'react'
import ReactDOM from 'react-dom'
import { LicenseInfo } from '@mui/x-data-grid-pro'

import { analyticsUtils } from 'utils'
import { muiConfig } from 'config'
import AuthProviders from 'context/auth'
import { ThemeTypeProvider } from 'context/theme'

import App from './App'
import reportWebVitals from './reportWebVitals'

import 'config/i18n'
import 'config/moment'

analyticsUtils.init()
LicenseInfo.setLicenseKey(muiConfig.license)

ReactDOM.render(
  <AuthProviders>
    <ThemeTypeProvider>
      <App />
    </ThemeTypeProvider>
  </AuthProviders>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
