import { formatUtils } from 'utils'

const createFormat = ({ name, latlong, tenant_id }) => {
  return {
    variables: {
      object: {
        name,
        latlong,
        tenant_id
      }
    }
  }
}

const updateFormat = ({
  id,
  name,
  email,
  type,
  un_locode,
  timezone_id,
  geolocation,
  address,
  primary_color,
  logo,
  report_width,
  report_height,
  report_orientation,
  report_font_size,
  initial_consecutive_folio_number,
  has_vpn
}) => {
  return {
    variables: {
      id,
      set: {
        name,
        email,
        type,
        un_locode,
        timezone_id,
        address,
        primary_color,
        logo,
        report_width: formatUtils.toNumberOrNull(report_width),
        report_height: formatUtils.toNumberOrNull(report_height),
        report_orientation,
        report_font_size: formatUtils.toNumberOrNull(report_font_size),
        initial_consecutive_folio_number: formatUtils.toNumberOrNull(
          initial_consecutive_folio_number
        ),
        has_vpn: has_vpn || false,
        geolocation: {
          type: 'Point',
          coordinates: [geolocation.lng, geolocation.lat]
        }
      }
    }
  }
}

const deleteFormat = id => {
  return {
    variables: {
      id
    }
  }
}

export default {
  createFormat,
  updateFormat,
  deleteFormat
}
