import React, { useState, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { ipfsConfig } from 'config'

const StyledImg = styled('img', {
  shouldForwardProp: prop => prop !== 'isZoomed'
})(({ isZoomed }) => ({
  maxWidth: 250,
  maxHeight: 175,
  height: 'auto',
  minHeight: 175,
  borderRadius: isZoomed ? 0 : 3
}))

function FileDetail({ label, value, divider = true }) {
  const [isZoomed, setIsZoomed] = useState(false)

  const { t } = useTranslation()

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom)
  }, [])

  if (!value) {
    return null
  }

  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='row'
      justifyContent='space-around'
      alignItems='center'
      borderBottom={divider ? 1 : 0}
      borderBottomColor='divider'
    >
      <Box display='flex' alignItems='center' justifyContent='center' flex={1}>
        <Typography variant='caption' gutterBottom>
          {t(label)}
        </Typography>
      </Box>
      <Box display='flex' alignItems='center' justifyContent='center' flex={1}>
        <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            my={2}
            flex={1}
          >
            <StyledImg src={`${ipfsConfig.url}${value}`} alt={value} />
          </Box>
        </ControlledZoom>
      </Box>
    </Box>
  )
}

FileDetail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  divider: PropTypes.bool
}

export default FileDetail
