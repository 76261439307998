import sha512 from 'crypto-js/sha512'

import authUtils from '../auth'
import arrayUtils from '../array'

const createFormat = ({
  first_name,
  last_name,
  email,
  user_name,
  password,
  role,
  location_ids
}) => {
  const object = {
    first_name,
    last_name,
    email,
    user_name,
    password: sha512(password).toString(),
    role,
    user_locations: { data: [] }
  }

  if (authUtils.isRoleBelongsToLocations(role) && location_ids) {
    location_ids.map(id => object.user_locations.data.push({ location_id: id }))
  }

  return {
    variables: {
      object
    }
  }
}

const updateFormat = (user, oldUser) => {
  const userCopy = { ...user }

  const variables = {
    id: userCopy.id,
    role: userCopy.role,
    deleteOrganizationIds: [],
    deleteLocationIds: [],
    insertOrganizations: [],
    insertLocations: []
  }

  if (
    authUtils.isTenantAdmin(variables.role) &&
    oldUser.user_locations.length > 0
  ) {
    oldUser.user_locations.map(({ location }) =>
      variables.deleteLocationIds.push(location.id)
    )
  }

  if (authUtils.isRoleBelongsToLocations(variables.role)) {
    const oldLocationIds = oldUser.user_locations.map(
      ({ location }) => location.id
    )

    const deleteLocationIds = arrayUtils.diff(
      oldLocationIds,
      userCopy.location_ids
    )

    variables.deleteLocationIds = deleteLocationIds

    const createLocationIds = arrayUtils.diff(
      userCopy.location_ids,
      oldLocationIds
    )

    createLocationIds.map(id =>
      variables.insertLocations.push({
        user_id: userCopy.id,
        location_id: id
      })
    )
  }

  if (authUtils.isOrganizationViewer(variables.role)) {
    const oldOrganizationIds = oldUser.user_organizations.map(
      ({ organization }) => organization.id
    )

    const deleteOrganizationIds = arrayUtils.diff(
      oldOrganizationIds,
      userCopy.organization_ids
    )

    variables.deleteOrganizationIds = deleteOrganizationIds

    const createOrganizationIds = arrayUtils.diff(
      userCopy.organization_ids,
      oldOrganizationIds
    )

    createOrganizationIds.map(id =>
      variables.insertOrganizations.push({
        user_id: userCopy.id,
        organization_id: id
      })
    )
  }

  return {
    variables
  }
}

const createOrganizationViewerFormat = userOrganization => {
  const userOrganizationCopy = { ...userOrganization }

  const objects = []

  userOrganizationCopy.organization_ids.map(id =>
    objects.push({ user_id: userOrganizationCopy.user_id, organization_id: id })
  )

  return {
    variables: {
      objects
    }
  }
}

const updateProfileFormat = ({
  id,
  first_name,
  last_name,
  email,
  user_name
}) => {
  return {
    variables: {
      id,
      first_name,
      last_name,
      email,
      user_name
    }
  }
}

const formatUserOrganizationViewer = ({
  first_name,
  last_name,
  email,
  user_name,
  password
}) => {
  return {
    first_name,
    last_name,
    email,
    user_name,
    password: sha512(password).toString()
  }
}

const deleteFormat = id => {
  return {
    variables: {
      id
    }
  }
}

const formatLogin = ({ emailOrUsername, password }) => {
  return {
    emailOrUsername,
    password: sha512(password).toString()
  }
}

const formatResetPassword = ({ user_name, password, secret }) => {
  return {
    user_name,
    secret,
    password: sha512(password).toString()
  }
}

const formatChangePassword = ({
  currentPassword,
  newPassword,
  newPasswordConfirmation
}) => {
  return {
    variables: {
      currentPassword: sha512(currentPassword).toString(),
      newPassword: sha512(newPassword).toString(),
      newPasswordConfirmation: sha512(newPasswordConfirmation).toString()
    }
  }
}

export default {
  createFormat,
  updateFormat,
  createOrganizationViewerFormat,
  updateProfileFormat,
  deleteFormat,
  formatLogin,
  formatUserOrganizationViewer,
  formatResetPassword,
  formatChangePassword
}
