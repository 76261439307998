import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { xGridUtils } from 'utils'

const initialPage = 0
const initialPageSize = 10
const initialSortModel = [{ field: 'id', sort: 'desc' }]
const initialFilterModel = undefined
const { getOrderBy, getWhere } = xGridUtils

function useXGridQueryParameters() {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const pageSize = parseInt(query.get('pageSize')) || initialPageSize
  const page = parseInt(query.get('page')) || initialPage
  const sortModelQuery = query.get('sortModel')
  const filterModelQuery = query.get('filterModel')
  const sortModel = sortModelQuery
    ? JSON.parse(sortModelQuery)
    : initialSortModel
  const filterModel =
    filterModelQuery && filterModelQuery !== 'undefined'
      ? JSON.parse(filterModelQuery)
      : initialFilterModel
  const orderBy = useMemo(
    () => getOrderBy(sortModel, initialSortModel),
    [sortModel]
  )
  const where = useMemo(() => getWhere(filterModel), [filterModel])

  return {
    pageSize,
    page,
    sortModelQuery,
    filterModelQuery,
    sortModel,
    filterModel,
    orderBy,
    where
  }
}

export default useXGridQueryParameters
