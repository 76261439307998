import { constants } from 'config'

const {
  ENUM_DATA: {
    CONTAINER_STATUSES,
    TRANSACTION_TYPES,
    BASE_TRANSACTION_TYPES,
    TRANSACTION_TRANSPORTATION_TYPES,
    TEMPLATE_TYPES
  }
} = constants

const isGateIn = value =>
  value === TRANSACTION_TYPES.GATE_IN_EMPTY ||
  value === TRANSACTION_TYPES.GATE_IN_LOADED ||
  value === BASE_TRANSACTION_TYPES.GATE_IN

const isGateInEmpty = value => value === TRANSACTION_TYPES.GATE_IN_EMPTY

const isGateInLoaded = value => value === TRANSACTION_TYPES.GATE_IN_LOADED

const isGateOut = value =>
  value === TRANSACTION_TYPES.GATE_OUT_EMPTY ||
  value === TRANSACTION_TYPES.GATE_OUT_LOADED ||
  value === BASE_TRANSACTION_TYPES.GATE_OUT ||
  value === TEMPLATE_TYPES.GATE_OUT_EMPTY_IMPORT ||
  value === TEMPLATE_TYPES.GATE_OUT_EMPTY_EXPORT ||
  value === TEMPLATE_TYPES.GATE_OUT_LOADED_IMPORT ||
  value === TEMPLATE_TYPES.GATE_OUT_LOADED_EXPORT

const isGateOutEmpty = value => value === TRANSACTION_TYPES.GATE_OUT_EMPTY

const isGateOutLoaded = value => value === TRANSACTION_TYPES.GATE_OUT_LOADED

const isEmpty = value => value === CONTAINER_STATUSES.EMPTY

const isLoaded = value => value === CONTAINER_STATUSES.LOADED

const isImport = value => value === TRANSACTION_TRANSPORTATION_TYPES.IMPORT

const isExport = value => value === TRANSACTION_TRANSPORTATION_TYPES.EXPORT

export default {
  isGateIn,
  isGateInEmpty,
  isGateInLoaded,
  isGateOut,
  isGateOutEmpty,
  isGateOutLoaded,
  isEmpty,
  isLoaded,
  isImport,
  isExport
}
