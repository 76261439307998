import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  user_name: '',
  password: '',
  role: ''
}

const schema = yup.object().shape({
  first_name: yup.string().required(validations.requiredField.code),
  last_name: yup.string().required(validations.requiredField.code),
  email: yup.string().email().required(validations.requiredField.code),
  user_name: yup.string().required(validations.requiredField.code),
  role: yup.string().required(validations.requiredField.code)
})

export default {
  initialValues,
  schema
}
