import { gql } from '@apollo/client'

const TRANSACTIONS_FRAGMENT = gql`
  fragment TransactionsFragment on transaction {
    id
    uuid
    unique_uuid
    version
    inventory_item {
      id
      container_number
      container_iso_type
      mgw_kilograms
      tare_kilograms
      container_year
      max_payload_kilograms
      volume_cbm
      release {
        id
        name
      }
    }
    location {
      id
      name
      timezone {
        id
        name
        label
      }
    }
    type
    container_status
    transportation_type
    booking_number
    container_condition
    cargo_weight_kilograms
    created_at
    updated_at
    driver_id
    plate_id
    driver_name
    is_damage
    stack
    comments
    transaction_gate_in_id
    template_id
    custom_fields
    pti_pass
    clean
    set_point_celsius
    return_temperature
    supply_temperature
    humidity_set_point
    humidity_actual
    ventilation
    consecutive_folio_number
    transaction_template {
      id
      name
    }
    client {
      id
      name
      trade_name
      has_credit
    }
    shipper {
      id
      name
      trade_name
    }
    consignee {
      id
      name
      trade_name
    }
    liner {
      id
      name
      trade_name
    }
    user {
      id
      first_name
      last_name
    }
    edi_messages(limit: 1, order_by: { created_at: desc }) {
      id
      message
      status
    }
  }
`

const TRANSACTION_FRAGMENT = gql`
  fragment TransactionFragment on transaction {
    id
    type
    container_status
    transportation_type
    booking_number
    comments
    container_condition
    cargo_weight_kilograms
    created_at
    updated_at
    driver_id
    driver_name
    is_damage
    latlong
    version
    uuid
    unique_uuid
    plate_id
    stack
    client_id
    shipper_id
    consignee_id
    liner_id
    template_id
    custom_fields
    location_id
    pti_pass
    clean
    set_point_celsius
    return_temperature
    supply_temperature
    humidity_set_point
    humidity_actual
    ventilation
    consecutive_folio_number
    transaction_template {
      id
      location_id
      transaction_template_type
      template
      is_default
    }
    inventory_item {
      id
      container_number
      container_iso_type
      container_year
      max_payload_kilograms
      mgw_kilograms
      tare_kilograms
      volume_cbm
    }
    photos {
      id
      type
      hash
      seals {
        id
        type
        value
      }
      damages {
        id
        type
      }
    }
    client {
      id
      name
      trade_name
      has_credit
    }
    shipper {
      id
      name
      trade_name
    }
    consignee {
      id
      name
      trade_name
    }
    liner {
      id
      name
      trade_name
    }
    location {
      id
      name
      timezone {
        id
        name
        label
      }
    }
    user {
      id
      first_name
      last_name
    }
    edi_messages(limit: 1, order_by: { created_at: desc }) {
      id
      message
      status
    }
  }
`

const INVENTORY_FRAGMENT = gql`
  fragment InventoryFragment on inventory {
    id
    uuid
    unique_uuid
    version
    inventory_item {
      id
      container_number
      container_iso_type
      container_year
      max_payload_kilograms
      mgw_kilograms
      tare_kilograms
      volume_cbm
      release {
        id
        name
      }
    }
    location {
      id
      name
      timezone {
        id
        name
        label
      }
    }
    type
    container_status
    transportation_type
    booking_number
    container_condition
    created_at
    updated_at
    driver_id
    plate_id
    driver_name
    is_damage
    stack
    pti_pass
    clean
    set_point_celsius
    return_temperature
    supply_temperature
    humidity_set_point
    humidity_actual
    ventilation
    custom_fields
    consecutive_folio_number
    client {
      id
      name
      has_credit
    }
    shipper {
      id
      name
    }
    consignee {
      id
      name
    }
    liner {
      id
      name
    }
    user {
      id
      first_name
      last_name
    }
    edi_messages(limit: 1, order_by: { created_at: desc }) {
      id
      message
      status
    }
  }
`

const PHOTOS_FRAGMENT = gql`
  fragment PhotosFragment on photo {
    id
    type
    hash
    seals {
      id
      type
      value
    }
    damages {
      id
      type
    }
  }
`

const SELECT_ORGANIZATION_FRAGMENT = gql`
  fragment SelectOrganizationFragment on organization {
    id
    name
    trade_name
    has_credit
  }
`

const GET_TRANSACTIONS_QUERY = gql`
  query transactionsQuery(
    $limit: Int
    $offset: Int
    $orderBy: [transaction_order_by!]
    $where: transaction_bool_exp
  ) {
    transactionsAggregate: transaction_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    transactions: transaction(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: $where
    ) {
      ...TransactionsFragment
    }
  }
  ${TRANSACTIONS_FRAGMENT}
`

const GET_TRANSACTIONS_SUBSCRIPTION = gql`
  subscription transactionsSubscription(
    $limit: Int
    $offset: Int
    $orderBy: [transaction_order_by!]
    $where: transaction_bool_exp
  ) {
    transactions: transaction(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: $where
    ) {
      ...TransactionsFragment
    }
  }
  ${TRANSACTIONS_FRAGMENT}
`

const GET_TRANSACTION = gql`
  query transaction($id: Int!) {
    transaction: transaction_by_pk(id: $id) {
      ...TransactionFragment
    }
  }
  ${TRANSACTION_FRAGMENT}
`

const GET_INVENTORY_QUERY = gql`
  query inventoryQuery(
    $limit: Int
    $offset: Int
    $orderBy: [inventory_order_by!]
    $where: inventory_bool_exp
  ) {
    transactionsAggregate: inventory_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    transactions: inventory(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: $where
    ) {
      ...InventoryFragment
    }
  }
  ${INVENTORY_FRAGMENT}
`

const GET_INVENTORY_SUBSCRIPTION = gql`
  subscription inventorySubscription(
    $limit: Int
    $offset: Int
    $orderBy: [inventory_order_by!]
    $where: inventory_bool_exp
  ) {
    transactions: inventory(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: $where
    ) {
      ...InventoryFragment
    }
  }
  ${INVENTORY_FRAGMENT}
`

const UPDATE_TRANSACTION = gql`
  mutation update_transaction($transaction: utrx_transaction_input!) {
    transaction: update_transaction_actions(transaction: $transaction) {
      id
    }
  }
`

const GET_FORM_DATA = gql`
  query transactionFormData {
    shippers: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "SHIPPER" } }
        }
      }
      order_by: { name: asc }
    ) {
      ...SelectOrganizationFragment
    }
    liners: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "LINER" } }
        }
      }
      order_by: { name: asc }
    ) {
      ...SelectOrganizationFragment
    }
    clients: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "CLIENT" } }
        }
      }
      order_by: { name: asc }
    ) {
      ...SelectOrganizationFragment
    }
    consignees: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "CONSIGNEE" } }
        }
      }
      order_by: { name: asc }
    ) {
      ...SelectOrganizationFragment
    }
  }
  ${SELECT_ORGANIZATION_FRAGMENT}
`

const GET_TRANSACTION_PHOTOS = gql`
  query getTransactionPhotos($transactionId: Int!) {
    photos: photo(where: { transaction_id: { _eq: $transactionId } }) {
      ...PhotosFragment
    }
  }
  ${PHOTOS_FRAGMENT}
`

export default {
  GET_TRANSACTIONS_QUERY,
  GET_TRANSACTIONS_SUBSCRIPTION,
  GET_TRANSACTION,
  GET_INVENTORY_QUERY,
  GET_INVENTORY_SUBSCRIPTION,
  UPDATE_TRANSACTION,
  GET_FORM_DATA,
  GET_TRANSACTION_PHOTOS
}
