import update from './update'
import create from './create'
import organizationViewer from './organizationViewer'
import profile from './profile'
import changePassword from './changePassword'

const schemas = {
  update,
  create,
  organizationViewer,
  profile,
  changePassword
}

export default schemas
