const formatTableData = ({ data, readOnly, inventoryItems }) => {
  if (!data?.inventory) {
    return []
  }

  if (readOnly) {
    return inventoryItems.map(({ transaction, ...inventoryItem }) => {
      return {
        ...transaction,
        inventory_item: {
          ...inventoryItem
        }
      }
    })
  }

  return data.inventory
}

export default {
  formatTableData
}
