import { gql } from '@apollo/client'

const INVENTORY_BY_LOCATION_ID_FRAGMENT = gql`
  fragment InventoryByLocationIdFragment on inventory {
    id
    container_status
    container_condition
    is_damage
    created_at
    inventory_item {
      id
      container_iso_type
    }
    client {
      id
      name
    }
  }
`

const TRANSACTION_BY_LOCATION_ID_FRAGMENT = gql`
  fragment TransactionByLocationIdFragment on transaction {
    id
    type
  }
`

const RELEASES_BY_LOCATION_ID_FRAGMENT = gql`
  fragment ReleasesByLocationIdFragment on release {
    id
    name
    inventory_items {
      id
      transaction {
        id
        type
      }
    }
  }
`

const GET_BASE_DATA = gql`
  query chartBaseData {
    locations: location(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
      status
    }
  }
`

const GET_INVENTORY_BY_LOCATION_ID_QUERY = gql`
  query inventoryByLocationIdChartQuery($location_id: Int!) {
    inventory: inventory(
      where: { inventory_item: { location_id: { _eq: $location_id } } }
    ) {
      ...InventoryByLocationIdFragment
    }
  }
  ${INVENTORY_BY_LOCATION_ID_FRAGMENT}
`

const GET_INVENTORY_BY_LOCATION_ID_SUBSCRIPTION = gql`
  subscription inventoryByLocationIdChartSubscription($location_id: Int!) {
    inventory: inventory(
      where: { inventory_item: { location_id: { _eq: $location_id } } }
    ) {
      ...InventoryByLocationIdFragment
    }
  }
  ${INVENTORY_BY_LOCATION_ID_FRAGMENT}
`

const GET_RELEASES_BY_LOCATION_ID_QUERY = gql`
  query releasesByLocationIdChartQuery($location_id: Int!) {
    releases: release(
      where: {
        deleted_at: { _is_null: true }
        location_id: { _eq: $location_id }
        inventory_items: {
          transaction: { type: { _in: [GATE_IN_EMPTY, GATE_IN_LOADED] } }
        }
      }
    ) {
      ...ReleasesByLocationIdFragment
    }
  }
  ${RELEASES_BY_LOCATION_ID_FRAGMENT}
`

const GET_RELEASES_BY_LOCATION_ID_SUBSCRIPTION = gql`
  subscription releasesByLocationIdChartSubscription($location_id: Int!) {
    releases: release(
      where: {
        deleted_at: { _is_null: true }
        location_id: { _eq: $location_id }
        inventory_items: {
          transaction: { type: { _in: [GATE_IN_EMPTY, GATE_IN_LOADED] } }
        }
      }
    ) {
      ...ReleasesByLocationIdFragment
    }
  }
  ${RELEASES_BY_LOCATION_ID_FRAGMENT}
`

const GET_TRANSACTIONS_OF_THE_MONTH_QUERY = gql`
  query transactionOfTheMonthQuery($from: timestamptz, $locationId: Int!) {
    transactions: transaction(
      where: {
        created_at: { _gte: $from }
        location_id: { _eq: $locationId }
        version: { _eq: 1 }
        type: { _in: [GATE_IN_EMPTY, GATE_IN_LOADED] }
      }
    ) {
      ...TransactionByLocationIdFragment
    }
  }
  ${TRANSACTION_BY_LOCATION_ID_FRAGMENT}
`

const GET_TRANSACTIONS_OF_THE_MONTH_SUBSCRIPTION = gql`
  subscription transactionOfTheMonthSubscription(
    $from: timestamptz
    $locationId: Int!
  ) {
    transactions: transaction(
      where: {
        created_at: { _gte: $from }
        location_id: { _eq: $locationId }
        version: { _eq: 1 }
        type: { _in: [GATE_IN_EMPTY, GATE_IN_LOADED] }
      }
    ) {
      ...TransactionByLocationIdFragment
    }
  }
  ${TRANSACTION_BY_LOCATION_ID_FRAGMENT}
`

export default {
  GET_BASE_DATA,
  GET_INVENTORY_BY_LOCATION_ID_QUERY,
  GET_INVENTORY_BY_LOCATION_ID_SUBSCRIPTION,
  GET_RELEASES_BY_LOCATION_ID_QUERY,
  GET_RELEASES_BY_LOCATION_ID_SUBSCRIPTION,
  GET_TRANSACTIONS_OF_THE_MONTH_QUERY,
  GET_TRANSACTIONS_OF_THE_MONTH_SUBSCRIPTION
}
