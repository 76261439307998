import React from 'react'
import PropTypes from 'prop-types'
import { LocationOn as LocationOnIcon } from '@mui/icons-material'

function Marker({ children = null }) {
  return children ?? <LocationOnIcon color='error' />
}

Marker.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
}

export default Marker
