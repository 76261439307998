import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import { useUser } from 'context/auth/user'

function AuthRoute({ allowedRoles = [], children, ...rest }) {
  const {
    user: { role: userRole }
  } = useUser()

  return (
    <Route {...rest}>
      {allowedRoles.some(role => role === userRole) ? (
        children
      ) : (
        <Redirect to='/' />
      )}
    </Route>
  )
}

AuthRoute.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired,
  rest: PropTypes.object
}

export default AuthRoute
