import arrayUtils from '../array'

const createFormat = organization => {
  const organizationCopy = { ...organization }

  const object = {
    name: organizationCopy.name,
    trade_name: organizationCopy.trade_name || null,
    id_number: organizationCopy.id_number || null,
    contact_name: organizationCopy.contact_name,
    phone_number: organizationCopy.phone_number,
    email: organizationCopy.email,
    has_credit: organizationCopy?.has_credit,
    tenant_id: organizationCopy.tenant_id,
    organization_organization_types: {
      data: []
    }
  }

  organizationCopy.type_ids.map(typeId =>
    object.organization_organization_types.data.push({
      organization_type_id: typeId
    })
  )

  return {
    variables: {
      object
    }
  }
}

const updateFormat = (organization, oldOrganization) => {
  const organizationCopy = { ...organization }

  const variables = {
    id: organizationCopy.id,
    name: organizationCopy.name,
    trade_name: organizationCopy.trade_name || null,
    id_number: organizationCopy.id_number || null,
    contact_name: organizationCopy.contact_name,
    phone_number: organizationCopy.phone_number,
    email: organizationCopy.email,
    has_credit: organizationCopy?.has_credit,
    deleteOrganizationIds: [],
    insertOrganizations: []
  }

  const oldOrganizationsTypes = oldOrganization.organization_types.map(
    ({ type }) => type.id
  )

  const deleteIds = arrayUtils.diff(
    oldOrganizationsTypes,
    organizationCopy.type_ids
  )

  const createIds = arrayUtils.diff(
    organizationCopy.type_ids,
    oldOrganizationsTypes
  )

  createIds.map(id =>
    variables.insertOrganizations.push({
      organization_id: organizationCopy.id,
      organization_type_id: id
    })
  )

  variables.deleteOrganizationIds = deleteIds

  return {
    variables
  }
}

const deleteFormat = id => {
  return {
    variables: {
      id
    }
  }
}

export default {
  createFormat,
  updateFormat,
  deleteFormat
}
