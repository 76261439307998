import { constants } from 'config'

const {
  ENUM_DATA: {
    EDI_COMPANIES: { KING_OCEAN }
  }
} = constants

const createFormat = ({
  name,
  client_id,
  client_code,
  locations,
  isaSenderQualifier,
  isaReceiverQualifier,
  isaSenderId,
  isaReceiverId,
  q5NumberReferenceQualifier,
  q5LocationCode,
  q5LocationNumber,
  timezone
}) => {
  return {
    variables: {
      object: {
        name,
        client_id,
        company: KING_OCEAN,
        template: {
          client_code,
          locations,
          isaSenderQualifier,
          isaReceiverQualifier,
          isaSenderId,
          isaReceiverId,
          q5NumberReferenceQualifier,
          q5LocationCode,
          q5LocationNumber,
          timezone
        }
      }
    }
  }
}

const formatFormValues = (
  initialValues,
  {
    id,
    name,
    is_enable,
    client_id,
    template: {
      client_code,
      locations,
      isaSenderQualifier,
      isaReceiverQualifier,
      isaSenderId,
      isaReceiverId,
      q5NumberReferenceQualifier,
      q5LocationCode,
      q5LocationNumber,
      timezone
    }
  }
) => {
  return {
    ...initialValues,
    id,
    name,
    is_enable,
    client_id,
    client_code,
    locations,
    isaSenderQualifier,
    isaReceiverQualifier,
    isaSenderId,
    isaReceiverId,
    q5NumberReferenceQualifier,
    q5LocationCode,
    q5LocationNumber,
    timezone
  }
}

const updateFormat = ({
  id,
  name,
  is_enable,
  client_id,
  client_code,
  locations,
  isaSenderQualifier,
  isaReceiverQualifier,
  isaSenderId,
  isaReceiverId,
  q5NumberReferenceQualifier,
  q5LocationCode,
  q5LocationNumber,
  timezone
}) => {
  return {
    variables: {
      id,
      name,
      is_enable,
      client_id,
      template: {
        client_code,
        locations,
        isaSenderQualifier,
        isaReceiverQualifier,
        isaSenderId,
        isaReceiverId,
        q5NumberReferenceQualifier,
        q5LocationCode,
        q5LocationNumber,
        timezone
      }
    }
  }
}

const deleteFormat = id => {
  return {
    variables: {
      id
    }
  }
}

export default {
  createFormat,
  formatFormValues,
  updateFormat,
  deleteFormat
}
