import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box, Tooltip, IconButton, FormHelperText } from '@mui/material'
import { MyLocation as MyLocationIcon } from '@mui/icons-material'
import { Skeleton } from '@mui/material'
import GoogleMapReact from 'google-map-react'
import { useTranslation } from 'react-i18next'

import { MapMarker } from 'components'
import { mapsConfig } from 'config'

function SelectLocationMap({
  readOnly,
  loading,
  error,
  initialLocation,
  onChangeLocation,
  center
}) {
  const { t } = useTranslation()

  const handleShowCurrentLocation = useCallback(() => {
    const geolocation = navigator?.geolocation
    if (geolocation) {
      geolocation.getCurrentPosition(position => {
        const { latitude: lat, longitude: lng } = position.coords
        onChangeLocation({
          lat,
          lng
        })
      })
    }
  }, [onChangeLocation])

  return (
    <Box my={2}>
      {loading ? (
        <Skeleton variant='rectangular' height='30vw' width='100%' />
      ) : (
        <>
          <Box height='20vw' width='100%'>
            <Box position='absolute' zIndex={1}>
              <Tooltip title={t('showYourLocation')}>
                <span>
                  <IconButton
                    onClick={handleShowCurrentLocation}
                    disabled={readOnly}
                    size='large'
                  >
                    <MyLocationIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>

            <GoogleMapReact
              bootstrapURLKeys={{ key: mapsConfig.apiKey, language: 'es' }}
              defaultCenter={initialLocation || mapsConfig.center}
              center={center}
              defaultZoom={mapsConfig.zoom}
              yesIWantToUseGoogleMapApiInternals={true}
              onClick={readOnly ? undefined : onChangeLocation}
            >
              {center ? <MapMarker lat={center.lat} lng={center.lng} /> : null}
            </GoogleMapReact>
          </Box>

          {error ? (
            <FormHelperText error={true} variant='outlined' margin={undefined}>
              {error}
            </FormHelperText>
          ) : null}
        </>
      )}
    </Box>
  )
}

SelectLocationMap.propTypes = {
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
  initialLocation: PropTypes.object,
  center: PropTypes.object,
  onChangeLocation: PropTypes.func
}

export default SelectLocationMap
