import React, {
  useReducer,
  createContext,
  useContext,
  useCallback
} from 'react'
import PropTypes from 'prop-types'

import { authUtils, fetchUtils, analyticsUtils } from 'utils'

const AuthContext = createContext()

function AuthProvider(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: false,
      error: null,
      data: authUtils.getUserData()
    }
  )

  const login = useCallback(async ({ emailOrUsername, password }) => {
    try {
      setState({
        loading: true,
        error: null
      })

      const { accessToken, refreshToken } = await fetchUtils.post(
        'auth/login',
        { emailOrUsername, password }
      )

      authUtils.setTokens({ accessToken, refreshToken })

      setState({
        loading: false,
        data: authUtils.getUserData()
      })

      analyticsUtils.sendEvent({
        category: 'Auth',
        action: 'Login successful',
        label: `The user ${emailOrUsername} just logged in`
      })
    } catch (error) {
      setState({
        loading: false,
        error
      })

      analyticsUtils.sendEvent({
        category: 'Auth',
        action: 'Login failed',
        label: `The user ${emailOrUsername} just failed to login`
      })
    }
  }, [])

  const logout = useCallback(() => {
    authUtils.cleanTokens()
    setState({
      data: authUtils.getUserData()
    })
    analyticsUtils.sendEvent({ category: 'Auth', action: 'Logout' })
  }, [])

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        ...state
      }}
      {...props}
    />
  )
}

function useAuth() {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  return context
}

AuthProvider.propTypes = {
  props: PropTypes.object
}

export { AuthProvider, useAuth }
