import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react'

import { constants } from 'config'

const {
  LOCAL_STORAGE_KEYS: { THEME_TYPE }
} = constants

const ThemeTypeContext = createContext(undefined)

function ThemeTypeProvider(props) {
  const [themeType, setThemeType] = useState(
    window.localStorage.getItem(THEME_TYPE) || 'dark'
  )

  useEffect(() => {
    if (themeType) {
      window.localStorage.setItem(THEME_TYPE, themeType)
    }
  }, [themeType])

  useEffect(() => {
    const persistType = window.localStorage.getItem(THEME_TYPE)

    if (persistType) {
      setThemeType(persistType)
    }
  }, [])

  const handleSetThemeType = useCallback(themeType => {
    setThemeType(themeType)
  }, [])

  return (
    <ThemeTypeContext.Provider
      value={{
        themeType,
        setThemeType: handleSetThemeType,
        isDarkTheme: themeType === 'dark',
        isLightTheme: themeType === 'light'
      }}
      {...props}
    />
  )
}

function useThemeType() {
  const context = useContext(ThemeTypeContext)

  if (context === undefined) {
    throw new Error(`useThemeType must be used within a ThemeTypeProvider`)
  }

  return context
}

export { ThemeTypeProvider, useThemeType }
