import { createTheme } from '@mui/material/styles'

const license =
  process.env.REACT_APP_MUI_LICENSE ||
  '022a70e99443161245e2ae5364857bbcT1JERVI6MjY3MzgsRVhQSVJZPTE2NTY4ODI3OTUwMDAsS0VZVkVSU0lPTj0x'

const getTheme = type =>
  createTheme({
    palette: {
      mode: type,
      primary: {
        main: '#2d3db5'
      },
      secondary: {
        main: '#e2882e'
      }
    },
    typography: {
      fontFamily: 'Lato, sans-serif'
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            textTransform: 'uppercase !important'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 0
          }
        }
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: 0
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 0
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 0
          }
        }
      }
    }
  })

export default {
  license,
  getTheme
}
