import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  id: '',
  name: '',
  address: '',
  port: '',
  username: '',
  password: '',
  location_id: ''
}

const schema = yup.object().shape({
  id: yup.number().required(validations.requiredField.code),
  name: yup.string().required(validations.requiredField.code),
  address: yup.string().required(validations.requiredField.code),
  port: yup.number().required(validations.requiredField.code),
  username: yup.string().required(validations.requiredField.code),
  password: yup.string().required(validations.requiredField.code),
  location_id: yup.string().required(validations.requiredField.code)
})

export default {
  initialValues,
  schema
}
