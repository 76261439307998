import React, { useCallback } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import {
  Brightness7 as Brightness7Icon,
  Brightness4 as Brightness4Icon
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { useThemeType } from 'context/theme'

function ThemeSelect() {
  const { t } = useTranslation()
  const { setThemeType, isDarkTheme } = useThemeType()

  const toggleThemeType = useCallback(
    () => setThemeType(isDarkTheme ? 'light' : 'dark'),
    [setThemeType, isDarkTheme]
  )

  return (
    <Tooltip title={t('toggleTheme')}>
      <IconButton
        onClick={toggleThemeType}
        aria-label={t('toggleTheme')}
        size='large'
      >
        {isDarkTheme ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Tooltip>
  )
}

export default ThemeSelect
