import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  user_id: '',
  organization_ids: []
}

const schema = yup.object().shape({
  user_id: yup.number().required(validations.requiredField.code),
  organization_ids: yup
    .array()
    .of(yup.number().required(validations.requiredField.code))
    .required(validations.requiredField.code)
})

export default {
  initialValues,
  schema
}
