import { gql } from '@apollo/client'

const LOCATION_FRAGMENT = gql`
  fragment LocationFragment on location {
    id
    name
    latlong
    un_locode
    type
    status
    geolocation
    email
    timezone_id
    address
    primary_color
    logo
    report_width
    report_height
    report_orientation
    report_font_size
    initial_consecutive_folio_number
    has_vpn
    created_at
    timezone {
      id
      name
      label
    }
  }
`

const GET_LOCATIONS = gql`
  query locations(
    $limit: Int
    $offset: Int
    $orderBy: [location_order_by!]
    $where: location_bool_exp
  ) {
    locationsAggregate: location_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    locations: location(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      ...LocationFragment
    }
  }
  ${LOCATION_FRAGMENT}
`

const GET_LOCATION = gql`
  query location($id: Int!) {
    location: location_by_pk(id: $id) {
      ...LocationFragment
    }
  }
  ${LOCATION_FRAGMENT}
`

const UPDATE_LOCATION = gql`
  mutation updateLocation($id: Int!, $set: location_set_input!) {
    location: update_location_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...LocationFragment
    }
  }
  ${LOCATION_FRAGMENT}
`

const DELETE_LOCATION = gql`
  mutation deleteLocation($id: Int!) {
    location: update_location_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
  }
`

const CREATE_LOCATION = gql`
  mutation insertLocation($object: location_insert_input!) {
    location: insert_location_one(object: $object) {
      ...LocationFragment
    }
  }
  ${LOCATION_FRAGMENT}
`

const GET_FORM_DATA = gql`
  query locationFormData {
    timezones: timezone(order_by: { name: asc }) {
      id
      name
    }
  }
`

export default {
  GET_LOCATIONS,
  GET_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  CREATE_LOCATION,
  GET_FORM_DATA
}
