import { gql } from '@apollo/client'

const ORGANIZATION_FRAGMENT = gql`
  fragment InventoryOrganizationFragment on organization {
    id
    name
    trade_name
  }
`

const TRANSACTION_TEMPLATE_FRAGMENT = gql`
  fragment TransactionTemplateFragment on transaction_template {
    id
    template
  }
`

const GET_FORM_DATA = gql`
  query inventoryFormData($location_id: Int!) {
    location: location_by_pk(id: $location_id) {
      id
      name
      latlong
    }
    shippers: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "SHIPPER" } }
        }
      }
      order_by: { name: asc }
    ) {
      ...InventoryOrganizationFragment
    }
    liners: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "LINER" } }
        }
      }
      order_by: { name: asc }
    ) {
      ...InventoryOrganizationFragment
    }
    clients: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "CLIENT" } }
        }
      }
      order_by: { name: asc }
    ) {
      ...InventoryOrganizationFragment
    }
    consignees: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "CONSIGNEE" } }
        }
      }
      order_by: { name: asc }
    ) {
      ...InventoryOrganizationFragment
    }
    GATE_IN_EMPTY_IMPORT: transaction_template(
      where: {
        is_default: { _eq: true }
        transaction_template_type: { _eq: GATE_IN_EMPTY_IMPORT }
      }
      order_by: { created_at: desc }
      limit: 1
    ) {
      ...TransactionTemplateFragment
    }
    GATE_IN_EMPTY_EXPORT: transaction_template(
      where: {
        is_default: { _eq: true }
        transaction_template_type: { _eq: GATE_IN_EMPTY_EXPORT }
      }
      order_by: { created_at: desc }
      limit: 1
    ) {
      ...TransactionTemplateFragment
    }
    GATE_IN_LOADED_IMPORT: transaction_template(
      where: {
        is_default: { _eq: true }
        transaction_template_type: { _eq: GATE_IN_LOADED_IMPORT }
      }
      order_by: { created_at: desc }
      limit: 1
    ) {
      ...TransactionTemplateFragment
    }
    GATE_IN_LOADED_EXPORT: transaction_template(
      where: {
        is_default: { _eq: true }
        transaction_template_type: { _eq: GATE_IN_LOADED_EXPORT }
      }
      order_by: { created_at: desc }
      limit: 1
    ) {
      ...TransactionTemplateFragment
    }
    GATE_OUT_EMPTY_IMPORT: transaction_template(
      where: {
        is_default: { _eq: true }
        transaction_template_type: { _eq: GATE_OUT_EMPTY_IMPORT }
      }
      order_by: { created_at: desc }
      limit: 1
    ) {
      ...TransactionTemplateFragment
    }
    GATE_OUT_EMPTY_EXPORT: transaction_template(
      where: {
        is_default: { _eq: true }
        transaction_template_type: { _eq: GATE_OUT_EMPTY_EXPORT }
      }
      order_by: { created_at: desc }
      limit: 1
    ) {
      ...TransactionTemplateFragment
    }
    GATE_OUT_LOADED_IMPORT: transaction_template(
      where: {
        is_default: { _eq: true }
        transaction_template_type: { _eq: GATE_OUT_LOADED_IMPORT }
      }
      order_by: { created_at: desc }
      limit: 1
    ) {
      ...TransactionTemplateFragment
    }
    GATE_OUT_LOADED_EXPORT: transaction_template(
      where: {
        is_default: { _eq: true }
        transaction_template_type: { _eq: GATE_OUT_LOADED_EXPORT }
      }
      order_by: { created_at: desc }
      limit: 1
    ) {
      ...TransactionTemplateFragment
    }
  }
  ${ORGANIZATION_FRAGMENT}
  ${TRANSACTION_TEMPLATE_FRAGMENT}
`

const CREATE_INVENTORY = gql`
  mutation insertTransaction($objects: [transaction_insert_input!]!) {
    transactions: insert_transaction(objects: $objects) {
      returning {
        id
      }
    }
  }
`

export default {
  GET_FORM_DATA,
  CREATE_INVENTORY
}
