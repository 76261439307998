import * as yup from 'yup'
import {
  validations as validationErrors,
  auth as authErrors
} from '@edenia/smartgate-errors-module'

const initialValues = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: ''
}

const schema = yup.object().shape({
  currentPassword: yup.string().required(validationErrors.requiredField.code),
  newPassword: yup
    .string()
    .notOneOf(
      [yup.ref('currentPassword')],
      authErrors.passwordsCannotBeTheSame.code
    )
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      'passwordRequirements'
    )
    .required(validationErrors.requiredField.code),
  newPasswordConfirmation: yup
    .string()
    .oneOf([yup.ref('newPassword')], authErrors.passwordsMustMatch.code)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      'passwordRequirements'
    )
    .required(validationErrors.requiredField.code)
})

export default {
  initialValues,
  schema
}
