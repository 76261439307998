import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import 'moment/locale/es'

import { constants } from 'config'

const {
  LOCAL_STORAGE_KEYS: { I18N }
} = constants

moment.locale(window.localStorage.getItem(I18N) || 'en')
