import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

import { drawerWidth } from '../'
import { StyledDrawerHeader } from '../Drawer'

const StyledMain = styled('main', {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  })
}))

function Container({ open, children }) {
  return (
    <StyledMain open={open}>
      <StyledDrawerHeader />
      {children}
    </StyledMain>
  )
}

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired,
  open: PropTypes.bool.isRequired
}

export default Container
