import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import {
  Box,
  Tooltip,
  IconButton,
  Typography,
  FormHelperText
} from '@mui/material'
import {
  AddAPhoto as AddAPhotoIcon,
  HighlightOff as HighlightOffIcon
} from '@mui/icons-material'
import Skeleton from '@mui/material/Skeleton'
import { useTranslation } from 'react-i18next'

import { ipfsConfig } from 'config'
import { FileDetail } from 'components'

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  flex: 1,
  width: '100%',
  height: 150
}))

function FileUploadPreview({
  onFileChange,
  file,
  spacing = false,
  loading = false,
  allowDelete = false,
  onDelete = () => {},
  name = 'FileUploadPreview',
  readOnly = false,
  withTitle = false,
  error
}) {
  const { t } = useTranslation()

  if (readOnly) {
    return <FileDetail value={file} label={name} />
  }

  return (
    <>
      {withTitle ? (
        <Typography component='div'>
          <Box my={3}>{t(name)}</Box>
        </Typography>
      ) : null}
      <Box
        display='flex'
        flexDirection='row'
        justifyContent={file ? 'space-around' : 'center'}
        alignItems='center'
        flex={1}
        width='100%'
        borderBottom={1}
        borderBottomColor='grey.300'
        borderBottomWidth={1}
        pb={1}
        mt={1}
        mb={spacing ? 2 : 0}
      >
        {file && !loading && (
          <Box
            display='flex'
            flex={1}
            alignItems='center'
            justifyContent='center'
            height={150}
          >
            <img src={`${ipfsConfig.url}${file}`} alt={file} height='100%' />
          </Box>
        )}

        {loading && <StyledSkeleton />}

        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          flex={1}
          height={150}
        >
          <input
            id={name}
            type='file'
            accept='image/*'
            onChange={onFileChange}
            style={{ display: 'none' }}
          />

          <label htmlFor={name}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              flex={1}
              height={150}
            >
              <Tooltip title={t('takePhoto')}>
                <AddAPhotoIcon />
              </Tooltip>

              {allowDelete && (
                <Tooltip title={t('delete')}>
                  <IconButton onClick={onDelete} size='large'>
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </label>
        </Box>
      </Box>
      {error ? (
        <FormHelperText error={true} variant='outlined' margin={undefined}>
          {error}
        </FormHelperText>
      ) : null}
    </>
  )
}

FileUploadPreview.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  file: PropTypes.string,
  spacing: PropTypes.bool,
  allowDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  name: PropTypes.string,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  withTitle: PropTypes.bool,
  error: PropTypes.string
}

export default FileUploadPreview
