import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  user_name: '',
  password: ''
}

const schema = yup.object().shape({
  user_name: yup.string().required(validations.requiredField.code),
  password: yup
    .string()
    .required(validations.requiredField.code)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      'passwordRequirements'
    )
})

export default {
  initialValues,
  schema
}
