import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import {
  Drawer,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  Tooltip,
  Box
} from '@mui/material'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Link, ThemeSelect } from 'components'
import routes from '../shared/routes'
import { useUser } from 'context/auth/user'
import { drawerWidth } from '../'

export const StyledDrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

function DrawerComponent({ handleDrawerClose, open }) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const {
    user: { role: userRole }
  } = useUser()

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
      variant='persistent'
      anchor='left'
      open={open}
    >
      <StyledDrawerHeader>
        <IconButton onClick={handleDrawerClose} size='large'>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </StyledDrawerHeader>
      <Divider />
      <Box
        display='flex'
        flex={1}
        flexDirection='column'
        justifyContent='space-between'
      >
        <List>
          {Object.keys(routes).map(route => {
            const { id, path, icon, name, allowedRoles } = routes[route]
            return allowedRoles.some(role => role === userRole) ? (
              <ListItem
                key={id}
                component={Link}
                to={path}
                sx={{ color: theme.palette.text.primary }}
                selected={pathname.startsWith(path)}
              >
                <ListItemIcon>
                  <Tooltip title={t(name)}>
                    <Icon>{icon}</Icon>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={t(name)} />
              </ListItem>
            ) : null
          })}
        </List>
        <Box display='flex' alignItems='center' justifyContent='center'>
          <ThemeSelect />
        </Box>
      </Box>
    </Drawer>
  )
}

DrawerComponent.propTypes = {
  handleDrawerClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default DrawerComponent
