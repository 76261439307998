import { gql } from '@apollo/client'

const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationFragment on organization {
    id
    name
    trade_name
    id_number
    contact_name
    phone_number
    email
    has_credit
    created_at
    organization_types: organization_organization_types {
      id
      type: organization_type {
        id
        name
      }
    }
  }
`

const GET_ORGANIZATIONS = gql`
  query organizations(
    $limit: Int
    $offset: Int
    $orderBy: [organization_order_by!]
    $where: organization_bool_exp
  ) {
    organizationsAggregate: organization_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    organizations: organization(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      ...OrganizationFragment
    }
  }
  ${ORGANIZATION_FRAGMENT}
`

const GET_ORGANIZATION = gql`
  query organization($id: Int!) {
    organization: organization_by_pk(id: $id) {
      ...OrganizationFragment
    }
  }
  ${ORGANIZATION_FRAGMENT}
`

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization(
    $id: Int!
    $name: String
    $trade_name: String
    $id_number: String
    $contact_name: String
    $phone_number: String
    $email: String
    $has_credit: Boolean
    $deleteOrganizationIds: [Int!]!
    $insertOrganizations: [organization_organization_type_insert_input!]!
  ) {
    delete_organization_organization_type(
      where: {
        organization_id: { _eq: $id }
        organization_type_id: { _in: $deleteOrganizationIds }
      }
    ) {
      affected_rows
    }
    insert_organization_organization_type(objects: $insertOrganizations) {
      affected_rows
    }
    organization: update_organization_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        trade_name: $trade_name
        id_number: $id_number
        contact_name: $contact_name
        phone_number: $phone_number
        email: $email
        has_credit: $has_credit
      }
    ) {
      ...OrganizationFragment
    }
  }
  ${ORGANIZATION_FRAGMENT}
`

const DELETE_ORGANIZATION = gql`
  mutation deleteOrganization($id: Int!) {
    organization: update_organization_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
  }
`

const CREATE_ORGANIZATION = gql`
  mutation insertOrganization($object: organization_insert_input!) {
    organization: insert_organization_one(object: $object) {
      ...OrganizationFragment
    }
  }
  ${ORGANIZATION_FRAGMENT}
`

const GET_ORGANIZATION_TYPES = gql`
  query organizationTypes {
    types: organization_type(order_by: { name: asc }) {
      id
      name
    }
  }
`

const GET_FORM_DATA = gql`
  query organizationFormData {
    types: organization_type(order_by: { name: asc }) {
      id
      name
    }
  }
`

export default {
  GET_ORGANIZATIONS,
  GET_ORGANIZATION,
  UPDATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  CREATE_ORGANIZATION,
  GET_ORGANIZATION_TYPES,
  GET_FORM_DATA
}
