import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  id: '',
  name: '',
  is_enable: '',
  client_id: '',
  client_code: '',
  locations: [
    {
      location_id: '',
      location_code: '',
      port_name: ''
    }
  ],
  isaSenderQualifier: '',
  isaReceiverQualifier: '',
  isaSenderId: '',
  isaReceiverId: '',
  q5NumberReferenceQualifier: '',
  q5LocationCode: '',
  q5LocationNumber: '',
  timezone: ''
}

const schema = yup.object().shape({
  id: yup.number().required(validations.requiredField.code),
  name: yup.string().required(validations.requiredField.code),
  is_enable: yup.boolean().required(validations.requiredField.code),
  client_id: yup.string().required(validations.requiredField.code),
  client_code: yup.string().required(validations.requiredField.code),
  locations: yup
    .array()
    .of(
      yup.object().shape({
        location_id: yup.string().required(validations.requiredField.code),
        location_code: yup.string().required(validations.requiredField.code),
        port_name: yup.string().required(validations.requiredField.code)
      })
    )
    .required(validations.requiredField.code),
  isaSenderQualifier: yup.string().required(validations.requiredField.code),
  isaReceiverQualifier: yup.string().required(validations.requiredField.code),
  isaSenderId: yup.string().required(validations.requiredField.code),
  isaReceiverId: yup.string().required(validations.requiredField.code),
  q5NumberReferenceQualifier: yup
    .string()
    .required(validations.requiredField.code),
  q5LocationCode: yup.string().required(validations.requiredField.code),
  q5LocationNumber: yup.string().required(validations.requiredField.code),
  timezone: yup.string().required(validations.requiredField.code)
})

export default {
  initialValues,
  schema
}
