import { gql } from '@apollo/client'

const RELEASE_FRAGMENT = gql`
  fragment ReleaseFragment on release {
    id
    name
    client_id
    release_party
    to_location_code
    un_locode
    location_id
    expiration
    created_at
    user_id
    approved
    user {
      id
      first_name
      last_name
    }
    client {
      id
      name
      trade_name
    }
    location {
      id
      name
      timezone {
        id
        name
        label
      }
    }
    inventory_items {
      id
      container_number
      container_iso_type
      container_year
      max_payload_kilograms
      volume_cbm
      release {
        id
        name
      }
      transaction {
        id
        uuid
        version
        type
        container_status
        transportation_type
        container_condition
        created_at
        is_damage
        stack
        pti_pass
        clean
        client {
          id
          name
          trade_name
        }
        shipper {
          id
          name
          trade_name
        }
        consignee {
          id
          name
          trade_name
        }
        liner {
          id
          name
          trade_name
        }
      }
    }
  }
`

const GET_FORM_DATA = gql`
  query releaseFormData {
    locations: location(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
    clients: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "CLIENT" } }
        }
      }
      order_by: { name: asc }
    ) {
      id
      name
      trade_name
    }
  }
`

const GET_LOCATIONS = gql`
  query releaseGetLocations {
    locations: location(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`

const GET_CLIENTS = gql`
  query releaseClients($location_id: Int!, $user_id: Int!) {
    clients: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "CLIENT" } }
        }
        tenant: { locations: { id: { _eq: $location_id } } }
        user_organizations: { user_id: { _eq: $user_id } }
      }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`

const GET_RELEASES = gql`
  query releases(
    $limit: Int
    $offset: Int
    $orderBy: [release_order_by!]
    $where: release_bool_exp
  ) {
    releasesAggregate: release_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    releases: release(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      ...ReleaseFragment
    }
  }
  ${RELEASE_FRAGMENT}
`

const GET_RELEASE = gql`
  query release($id: Int!) {
    release: release_by_pk(id: $id) {
      ...ReleaseFragment
    }
  }
  ${RELEASE_FRAGMENT}
`

const UPDATE_RELEASE = gql`
  mutation updaterRelease(
    $id: Int!
    $name: String!
    $release_party: String!
    $to_location_code: String
    $un_locode: String
    $expiration: date!
    $approved: Boolean
    $deleteInventoryItemIds: [Int!]!
    $insertInventoryItemIds: [Int!]!
  ) {
    deletedInventoryItems: update_inventory_item(
      where: { id: { _in: $deleteInventoryItemIds } }
      _set: { release_id: null }
    ) {
      affected_rows
    }
    insertedInventoryItems: update_inventory_item(
      where: { id: { _in: $insertInventoryItemIds } }
      _set: { release_id: $id }
    ) {
      affected_rows
    }
    release: update_release_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        release_party: $release_party
        expiration: $expiration
        approved: $approved
        to_location_code: $to_location_code
        un_locode: $un_locode
      }
    ) {
      ...ReleaseFragment
    }
  }
  ${RELEASE_FRAGMENT}
`

const UPDATE_RELEASE_ORGANIZATION_VIEWER = gql`
  mutation updaterReleaseOrganizationViewer(
    $id: Int!
    $name: String!
    $release_party: String!
    $to_location_code: String
    $un_locode: String
    $expiration: date!
    $deleteInventoryItemIds: [Int!]!
    $insertInventoryItemIds: [Int!]!
  ) {
    deletedInventoryItems: update_inventory_item(
      where: { id: { _in: $deleteInventoryItemIds } }
      _set: { release_id: null }
    ) {
      affected_rows
    }
    insertedInventoryItems: update_inventory_item(
      where: { id: { _in: $insertInventoryItemIds } }
      _set: { release_id: $id }
    ) {
      affected_rows
    }
    release: update_release_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        release_party: $release_party
        to_location_code: $to_location_code
        un_locode: $un_locode
        expiration: $expiration
      }
    ) {
      ...ReleaseFragment
    }
  }
  ${RELEASE_FRAGMENT}
`

const DELETE_RELEASE = gql`
  mutation deleteRelease($id: Int!) {
    release: update_release_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
    inventoryItems: update_inventory_item(
      where: { release_id: { _eq: $id } }
      _set: { release_id: null }
    ) {
      affected_rows
    }
  }
`

const CREATE_RELEASE = gql`
  mutation insertRelease($object: release_insert_input!) {
    release: insert_release_one(object: $object) {
      id
    }
  }
`

const UPDATE_INVENTORY_ITEMS = gql`
  mutation update_inventory_items($inventory_items: [Int!], $release_id: Int!) {
    update_inventory_item(
      where: { id: { _in: $inventory_items } }
      _set: { release_id: $release_id }
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`

const GET_INVENTORY_BY_LOCATION_AND_CLIENT_ID = gql`
  query inventory_by_location_and_client($location_id: Int!, $client_id: Int!) {
    inventory: inventory(
      where: {
        inventory_item: { location_id: { _eq: $location_id } }
        client_id: { _eq: $client_id }
      }
      order_by: { id: desc }
    ) {
      id
      uuid
      version
      inventory_item {
        id
        container_number
        container_iso_type
        container_year
        max_payload_kilograms
        volume_cbm
        release {
          id
          name
        }
      }
      type
      container_status
      transportation_type
      container_condition
      created_at
      is_damage
      stack
      pti_pass
      clean
      client {
        id
        name
        trade_name
      }
      shipper {
        id
        name
        trade_name
      }
      consignee {
        id
        name
        trade_name
      }
      liner {
        id
        name
        trade_name
      }
    }
  }
`

export default {
  GET_FORM_DATA,
  GET_LOCATIONS,
  GET_CLIENTS,
  GET_RELEASES,
  GET_RELEASE,
  UPDATE_RELEASE,
  UPDATE_RELEASE_ORGANIZATION_VIEWER,
  DELETE_RELEASE,
  CREATE_RELEASE,
  UPDATE_INVENTORY_ITEMS,
  GET_INVENTORY_BY_LOCATION_AND_CLIENT_ID
}
