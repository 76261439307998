import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  id: '',
  role: '',
  location_ids: [],
  organization_ids: []
}

const schema = yup.object().shape({
  id: yup.number().required(validations.requiredField.code),
  role: yup.string().required(validations.requiredField.code),
  location_ids: yup
    .array()
    .of(yup.number().required(validations.requiredField.code)),
  organization_ids: yup
    .array()
    .of(yup.number().required(validations.requiredField.code))
})

export default {
  initialValues,
  schema
}
