import createMaersk from './create/maersk'
import updateMaersk from './update/maersk'
import createKingOcean from './create/kingOcean'
import updateKingOcean from './update/kingOcean'

const schemas = {
  createMaersk,
  updateMaersk,
  createKingOcean,
  updateKingOcean
}

export default schemas
