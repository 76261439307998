import React from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray, getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  Box,
  MenuItem,
  CircularProgress,
  Typography,
  Tooltip,
  IconButton,
  FormHelperText
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material'

import { constants } from 'config'
import {
  BaseTextField,
  FileUploadPreview,
  FileDetail,
  FileUploadTextField
} from 'components'
import { dateUtils } from 'utils'
import { iso_types, bool_options, container_conditions } from './data.json'
import { useFileUpload } from 'hooks'

const {
  FIELD_TYPES: { NUMBER, STRING, BOOLEAN, SELECT, PHOTO, MULTI_SELECT },
  DEFAULT_FIELDS: {
    CONTAINER_ISO_TYPE,
    MGW_KILOGRAMS,
    MAX_PAYLOAD_KILOGRAMS,
    TARE_KILOGRAMS,
    CARGO_WEIGHT_KILOGRAMS,
    VOLUME_CBM,
    CONTAINER_YEAR,
    LINER_ID,
    SHIPPER_ID,
    CONSIGNEE_ID,
    BOOKING_NUMBER,
    DRIVER_NAME,
    DRIVER_ID,
    PLATE_ID,
    IS_DAMAGE,
    CONTAINER_CONDITION,
    STACK,
    COMMENTS,
    PTI_PASS,
    CLEAN,
    SET_POINT_CELSIUS,
    RETURN_TEMPERATURE,
    SUPPLY_TEMPERATURE,
    HUMIDITY_SET_POINT,
    HUMIDITY_ACTUAL,
    VENTILATION
  },
  DOOR_FIELDS: {
    ID,
    UUID,
    CONTAINER_NUMBER,
    TRANSPORTATION_TYPE,
    CONTAINER_STATUS,
    CLIENT_ID,
    TYPE,
    CONSECUTIVE_FOLIO_NUMBER,
    CREATED_BY,
    CREATED_AT,
    UPDATED_AT
  },
  PHOTO_FIELDS: {
    CONTAINER_PHOTO,
    CONTAINER_EMPTY_PHOTO,
    DRIVER_ID_PHOTO,
    DRIVER_PHOTO,
    PLATE_PHOTO,
    SEAL_PHOTOS,
    DAMAGE_PHOTOS,
    CONTAINER_CONDITION_PHOTOS
  },
  ENUM_DATA: {
    PHOTO_TYPES: {
      CONTAINER: CONTAINER_PHOTO_TYPE,
      CONTAINER_EMPTY: CONTAINER_EMPTY_PHOTO_TYPE,
      DRIVER_ID: DRIVER_ID_PHOTO_TYPE,
      DRIVER: DRIVER_PHOTO_TYPE,
      PLATE: PLATE_PHOTO_TYPE,
      CUSTOM: CUSTOM_PHOTO_TYPE,
      DAMAGE: DAMAGE_PHOTO_TYPE,
      CONTAINER_CONDITION: CONTAINER_CONDITION_PHOTO_TYPE
    },
    SEAL_TYPES,
    DAMAGE_TYPES
  }
} = constants

const getOrganizationValues = name => {
  switch (name) {
    case CLIENT_ID:
      return {
        label: 'client',
        organizationType: 'clients',
        field: 'client'
      }

    case LINER_ID:
      return {
        label: 'liner',
        organizationType: 'liners',
        field: 'liner'
      }

    case SHIPPER_ID:
      return {
        label: 'shipper',
        organizationType: 'shippers',
        field: 'shipper'
      }

    case CONSIGNEE_ID:
      return {
        label: 'consignee',
        organizationType: 'consignees',
        field: 'consignee'
      }

    default:
      return null
  }
}

const getPhotoType = fieldName => {
  switch (fieldName) {
    case CONTAINER_PHOTO:
      return CONTAINER_PHOTO_TYPE

    case CONTAINER_EMPTY_PHOTO:
      return CONTAINER_EMPTY_PHOTO_TYPE

    case DRIVER_ID_PHOTO:
      return DRIVER_ID_PHOTO_TYPE

    case DRIVER_PHOTO:
      return DRIVER_PHOTO_TYPE

    case PLATE_PHOTO:
      return PLATE_PHOTO_TYPE

    default:
      return CUSTOM_PHOTO_TYPE
  }
}

function FieldsFactory({
  isDefault = true,
  name,
  type,
  formikProps,
  options,
  readOnly = false,
  disabled = false,
  isEditable = false,
  formData,
  loadingFormData
}) {
  const { t } = useTranslation()
  const { upload, loading } = useFileUpload()
  const { touched, errors, values, setFieldValue } = formikProps

  if (isDefault) {
    switch (name) {
      case CONTAINER_ISO_TYPE:
        return (
          <Field
            name={name}
            as={BaseTextField}
            select
            label={t(name)}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
            readOnly={readOnly}
            disabled={disabled}
          >
            {iso_types.map(iso => {
              return (
                <MenuItem key={iso.code} value={iso.code}>
                  {`${iso.code} - ${t(iso.description)}`}
                </MenuItem>
              )
            })}
          </Field>
        )

      case MGW_KILOGRAMS:
      case MAX_PAYLOAD_KILOGRAMS:
      case TARE_KILOGRAMS:
      case CARGO_WEIGHT_KILOGRAMS:
      case VOLUME_CBM:
      case SET_POINT_CELSIUS:
      case RETURN_TEMPERATURE:
      case SUPPLY_TEMPERATURE:
      case HUMIDITY_SET_POINT:
      case HUMIDITY_ACTUAL:
      case VENTILATION:
      case CONSECUTIVE_FOLIO_NUMBER:
        return (
          <Field
            name={name}
            type='number'
            as={BaseTextField}
            label={t(name)}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
            readOnly={readOnly}
            disabled={disabled}
          />
        )

      case TYPE:
        return (
          <Field
            name={name}
            as={BaseTextField}
            label={t(name)}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
            readOnly={readOnly}
            disabled={disabled}
            value={t(values[TYPE])}
          />
        )

      case CREATED_BY:
        return (
          <Field
            name={name}
            as={BaseTextField}
            label={t(name)}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
            readOnly={readOnly}
            disabled={disabled}
            value={`${values?.user?.first_name} ${values?.user?.last_name}`}
          />
        )

      case CREATED_AT:
      case UPDATED_AT:
        return (
          <Field
            name={name}
            as={BaseTextField}
            label={t(name)}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
            readOnly={readOnly}
            disabled={disabled}
            value={moment(values?.[name]).format('DD/MM/YYYY HH:mm')}
          />
        )

      case CLIENT_ID:
      case LINER_ID:
      case SHIPPER_ID:
      case CONSIGNEE_ID: {
        const organizationValues = getOrganizationValues(name)
        if (!organizationValues) {
          return null
        }

        const { label, organizationType, field } = organizationValues
        const isClient = name === CLIENT_ID

        return (
          <Field name={name}>
            {({ field: { name } }) => {
              const organization = values?.[field]
              const helperText = isClient
                ? organization && organization?.has_credit !== null
                  ? `${t('has_credit')} : ${t(
                      organization?.has_credit?.toString().toUpperCase()
                    )}`
                  : ''
                : ''

              if (!isEditable) {
                return (
                  <BaseTextField
                    name={name}
                    label={t(label)}
                    value={
                      organization?.trade_name
                        ? `${organization?.name} - ${organization?.trade_name}`
                        : organization?.name
                    }
                    readOnly
                    helperText={helperText}
                  />
                )
              }

              return (
                <Autocomplete
                  id={name}
                  loading={loadingFormData}
                  options={formData?.[organizationType] || []}
                  getOptionLabel={option =>
                    option?.trade_name
                      ? `${option.name} - ${option.trade_name}`
                      : option.name
                  }
                  value={values?.[field]}
                  onChange={(_, value) => {
                    if (value) {
                      setFieldValue(field, value)
                      setFieldValue(name, value.id)
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loadingText={t('loading')}
                  noOptionsText={t('noOptions')}
                  renderInput={params => {
                    const helperText = isClient
                      ? touched?.[name] && errors?.[name]
                        ? t(errors[name])
                        : values?.client && values?.client?.has_credit !== null
                        ? `${t('has_credit')} : ${t(
                            values?.client?.has_credit?.toString().toUpperCase()
                          )}`
                        : ''
                      : touched?.[name] && errors?.[name]

                    return (
                      <BaseTextField
                        {...params}
                        label={t(label)}
                        error={!!(touched[name] && errors[name])}
                        helperText={helperText}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingFormData ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )
                  }}
                />
              )
            }}
          </Field>
        )
      }

      case ID:
        return (
          <Field
            name={name}
            as={BaseTextField}
            label={t(name)}
            value={`${values[name]}-${values.version}`}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
            readOnly={readOnly}
            disabled={disabled}
          />
        )

      case UUID:
      case CONTAINER_NUMBER:
      case TRANSPORTATION_TYPE:
      case CONTAINER_STATUS:
      case BOOKING_NUMBER:
      case DRIVER_NAME:
      case DRIVER_ID:
      case PLATE_ID:
      case STACK:
      case COMMENTS:
        return (
          <Field
            name={name}
            as={BaseTextField}
            label={t(name)}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
            readOnly={readOnly}
            disabled={disabled}
          />
        )

      case CONTAINER_YEAR:
        return (
          <Field
            name={name}
            type='number'
            as={BaseTextField}
            select
            label={t(name)}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
            readOnly={readOnly}
            disabled={disabled}
          >
            {dateUtils.getArrayOfLastYears().map(year => {
              return (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              )
            })}
          </Field>
        )

      case CONTAINER_PHOTO:
      case CONTAINER_EMPTY_PHOTO:
      case DRIVER_ID_PHOTO:
      case DRIVER_PHOTO:
      case PLATE_PHOTO:
        return (
          <Field name={name}>
            {({ field: { value } }) => {
              return (
                <FileUploadPreview
                  name={name}
                  readOnly={readOnly}
                  withTitle={true}
                  onFileChange={async ({
                    target: {
                      validity,
                      files: [file]
                    }
                  }) => {
                    const photoType = getPhotoType(name)

                    if (validity.valid && file) {
                      const response = await upload({
                        url: 'upload/photo',
                        file,
                        type: photoType
                      })
                      setFieldValue(name, response)
                    }
                  }}
                  file={value?.hash}
                  loading={loading}
                  error={touched[name] && t(errors[name]?.hash)}
                />
              )
            }}
          </Field>
        )

      case SEAL_PHOTOS:
        return (
          <FieldArray name={name}>
            {({ push, remove }) => {
              return (
                <>
                  {values?.[name]?.length > 0 || isEditable ? (
                    <Typography component='div'>
                      <Box mt={5}>{t(name)}</Box>
                    </Typography>
                  ) : null}

                  {values?.[name]?.map((seal, index) => {
                    return (
                      <Field name={`${name}.${index}`} key={`${name}.${index}`}>
                        {({ field, form: { setFieldValue } }) => {
                          if (!isEditable) {
                            return (
                              <FileDetail
                                value={field?.value?.hash}
                                label={`${t(field?.value?.seal_type)} / ${
                                  field?.value?.value
                                }`}
                              />
                            )
                          }

                          return (
                            <>
                              <BaseTextField
                                select
                                value={field?.value?.seal_type}
                                onChange={({
                                  target: { value: seal_type }
                                }) => {
                                  setFieldValue(`${name}.${index}`, {
                                    ...field.value,
                                    seal_type
                                  })
                                }}
                                label={t('seal_type')}
                                error={
                                  !!(
                                    getIn(touched, `${name}[${index}]`) &&
                                    getIn(errors, `${name}[${index}].seal_type`)
                                  )
                                }
                                helperText={
                                  getIn(touched, `${name}[${index}]`) &&
                                  getIn(
                                    errors,
                                    `${name}[${index}].seal_type`
                                  ) &&
                                  t(
                                    getIn(errors, `${name}[${index}].seal_type`)
                                  )
                                }
                              >
                                {Object.keys(SEAL_TYPES).map(key => {
                                  const value = SEAL_TYPES[key]
                                  return (
                                    <MenuItem key={value} value={value}>
                                      {t(value)}
                                    </MenuItem>
                                  )
                                })}
                              </BaseTextField>
                              <FileUploadTextField
                                spacing
                                allowDelete
                                onDelete={() => remove(index)}
                                onFileChange={async ({
                                  target: {
                                    validity,
                                    files: [file]
                                  }
                                }) => {
                                  try {
                                    if (validity.valid && file) {
                                      setFieldValue(`${name}.${index}`, {
                                        ...field.value,
                                        loading: true
                                      })
                                      const { photo, seal_id } = await upload({
                                        url: 'upload/seal-photo',
                                        file,
                                        key: PHOTO,
                                        throwError: true
                                      })

                                      setFieldValue(`${name}.${index}`, {
                                        ...field.value,
                                        ...photo,
                                        value: seal_id
                                      })
                                    }
                                  } catch (error) {
                                    setFieldValue(`${name}.${index}`, {
                                      ...field.value,
                                      loading: false
                                    })
                                  }
                                }}
                                {...field}
                                label={t('seal_id')}
                                file={field?.value?.hash}
                                loading={!!field?.value?.loading}
                                value={field?.value?.value}
                                error={
                                  !!(
                                    getIn(touched, `${name}[${index}]`) &&
                                    getIn(errors, `${name}[${index}].value`)
                                  )
                                }
                                helperText={
                                  getIn(touched, `${name}[${index}]`) &&
                                  t(getIn(errors, `${name}[${index}].value`))
                                }
                                fileError={
                                  getIn(touched, `${name}.${index}`) &&
                                  t(getIn(errors, `${name}.${index}.hash`))
                                }
                                onChange={({ target: { value: seal_id } }) => {
                                  setFieldValue(`${name}.${index}`, {
                                    ...field.value,
                                    value: seal_id
                                  })
                                }}
                              />
                            </>
                          )
                        }}
                      </Field>
                    )
                  })}

                  {isEditable && (
                    <>
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        mb={1}
                      >
                        <Tooltip title={t('addSeal')}>
                          <span>
                            <IconButton
                              onClick={() => {
                                push({
                                  hash: '',
                                  value: ''
                                })
                              }}
                              disabled={
                                values?.[name] && values?.[name]?.length === 4
                              }
                              size='large'
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>

                      {touched?.[name] && typeof errors?.[name] === 'string' ? (
                        <FormHelperText
                          error={true}
                          variant='outlined'
                          margin={undefined}
                        >
                          {t(errors[name])}
                        </FormHelperText>
                      ) : null}
                    </>
                  )}
                </>
              )
            }}
          </FieldArray>
        )

      case DAMAGE_PHOTOS:
        return values?.is_damage === true || values?.is_damage === 'true' ? (
          <FieldArray name={name}>
            {({ push, remove }) => {
              return (
                <>
                  {values?.[name]?.length > 0 || isEditable ? (
                    <Typography component='div'>
                      <Box mt={5}>{t(name)}</Box>
                    </Typography>
                  ) : null}

                  {values?.[name]?.map((damage, index) => {
                    return (
                      <Field name={`${name}.${index}`} key={`${name}.${index}`}>
                        {({ field, form: { setFieldValue } }) => {
                          if (!isEditable) {
                            return (
                              <FileDetail
                                key={`${field?.value?.hash}-${index}`}
                                label={`${t(field?.value?.type)} / ${t(
                                  field?.value?.damage_type
                                )}`}
                                value={field?.value?.hash}
                              />
                            )
                          }

                          return (
                            <>
                              <BaseTextField
                                select
                                value={field?.value?.damage_type}
                                onChange={({
                                  target: { value: damage_type }
                                }) => {
                                  setFieldValue(`${name}.${index}`, {
                                    ...field?.value,
                                    damage_type
                                  })
                                }}
                                label={t('damage_type')}
                                error={
                                  !!(
                                    getIn(touched, `${name}[${index}]`) &&
                                    getIn(
                                      errors,
                                      `${name}[${index}].damage_type`
                                    )
                                  )
                                }
                                helperText={
                                  getIn(touched, `${name}[${index}]`) &&
                                  getIn(
                                    errors,
                                    `${name}[${index}].damage_type`
                                  ) &&
                                  t(
                                    getIn(
                                      errors,
                                      `${name}[${index}].damage_type`
                                    )
                                  )
                                }
                              >
                                {Object.keys(DAMAGE_TYPES).map(key => {
                                  const value = DAMAGE_TYPES[key]
                                  return (
                                    <MenuItem key={value} value={value}>
                                      {t(value)}
                                    </MenuItem>
                                  )
                                })}
                              </BaseTextField>
                              <FileUploadPreview
                                allowDelete
                                onDelete={() => remove(index)}
                                name={field.name}
                                onFileChange={async ({
                                  target: {
                                    validity,
                                    files: [file]
                                  }
                                }) => {
                                  try {
                                    if (validity.valid && file) {
                                      setFieldValue(`${name}.${index}`, {
                                        ...field.value,
                                        loading: true
                                      })

                                      const response = await upload({
                                        url: 'upload/photo',
                                        file,
                                        type: DAMAGE_PHOTO_TYPE,
                                        throwError: true
                                      })

                                      setFieldValue(`${name}.${index}`, {
                                        ...field.value,
                                        ...response
                                      })
                                    }
                                  } catch (error) {
                                    setFieldValue(`${name}.${index}`, {
                                      ...field.value,
                                      loading: false
                                    })
                                  }
                                }}
                                file={field?.value?.hash}
                                loading={!!field?.value?.loading}
                                error={
                                  getIn(touched, `${name}.${index}`) &&
                                  t(getIn(errors, `${name}.${index}.hash`))
                                }
                              />
                            </>
                          )
                        }}
                      </Field>
                    )
                  })}

                  {isEditable && (
                    <>
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        mb={1}
                      >
                        <Tooltip title={t('addDamage')}>
                          <span>
                            <IconButton
                              onClick={() => {
                                push({})
                              }}
                              disabled={values?.[name]?.length === 4}
                              size='large'
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                      {touched?.[name] && typeof errors?.[name] === 'string' ? (
                        <FormHelperText
                          error={true}
                          variant='outlined'
                          margin={undefined}
                        >
                          {t(errors[name])}
                        </FormHelperText>
                      ) : null}
                    </>
                  )}
                </>
              )
            }}
          </FieldArray>
        ) : null

      case CONTAINER_CONDITION_PHOTOS:
        return (
          <FieldArray name={name}>
            {({ push, remove }) => {
              return (
                <>
                  {values?.[name]?.length > 0 || isEditable ? (
                    <Typography component='div'>
                      <Box mt={5}>{t(name)}</Box>
                    </Typography>
                  ) : null}

                  {values?.[name]?.map((condition, index) => {
                    return (
                      <Field name={`${name}.${index}`} key={`${name}.${index}`}>
                        {({ field, form: { setFieldValue } }) => {
                          if (!isEditable) {
                            return (
                              <FileDetail
                                value={field?.value?.hash}
                                label={field?.value?.type}
                              />
                            )
                          }

                          return (
                            <FileUploadPreview
                              name={field.name}
                              allowDelete
                              onDelete={() => remove(index)}
                              onFileChange={async ({
                                target: {
                                  validity,
                                  files: [file]
                                }
                              }) => {
                                try {
                                  if (validity.valid && file) {
                                    setFieldValue(`${name}.${index}`, {
                                      ...field.value,
                                      loading: true
                                    })

                                    const response = await upload({
                                      url: 'upload/photo',
                                      file,
                                      type: CONTAINER_CONDITION_PHOTO_TYPE,
                                      throwError: true
                                    })

                                    setFieldValue(`${name}.${index}`, {
                                      ...field.value,
                                      ...response
                                    })
                                  }
                                } catch (error) {
                                  setFieldValue(`${name}.${index}`, {
                                    ...field.value,
                                    loading: false
                                  })
                                }
                              }}
                              file={field?.value?.hash}
                              loading={!!field?.value?.loading}
                              error={
                                getIn(touched, `${name}.${index}`) &&
                                t(getIn(errors, `${name}.${index}.hash`))
                              }
                            />
                          )
                        }}
                      </Field>
                    )
                  })}

                  {isEditable && (
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      mb={1}
                    >
                      <Tooltip title={t('addContainerCondition')}>
                        <span>
                          <IconButton
                            onClick={() => {
                              push({})
                            }}
                            disabled={values?.[name]?.length === 4}
                            size='large'
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Box>
                  )}
                </>
              )
            }}
          </FieldArray>
        )

      case IS_DAMAGE:
      case PTI_PASS:
      case CLEAN:
        return (
          <Field
            name={name}
            as={BaseTextField}
            select
            label={t(name)}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
            readOnly={readOnly}
            disabled={disabled}
          >
            {bool_options.map(({ id, value, name }) => (
              <MenuItem key={id} value={value}>
                {t(name)}
              </MenuItem>
            ))}
          </Field>
        )

      case CONTAINER_CONDITION:
        return (
          <Field
            name={name}
            as={BaseTextField}
            select
            label={t(name)}
            error={!!(touched[name] && errors[name])}
            helperText={touched[name] && t(errors[name])}
            readOnly={readOnly}
            disabled={disabled}
          >
            {container_conditions.map(({ id, value }) => (
              <MenuItem key={id} value={value}>
                {value}
              </MenuItem>
            ))}
          </Field>
        )

      default:
        return <div>{name}</div>
    }
  }

  switch (type) {
    case NUMBER:
    case STRING:
      return (
        <Field
          name={name}
          type={type === NUMBER ? 'number' : 'string'}
          as={BaseTextField}
          label={name}
          error={!!(touched[name] && errors[name])}
          helperText={touched[name] && t(errors[name])}
          readOnly={readOnly}
          disabled={disabled}
        />
      )

    case BOOLEAN:
      return (
        <Field
          name={name}
          as={BaseTextField}
          select
          label={name}
          error={!!(touched[name] && errors[name])}
          helperText={touched[name] && t(errors[name])}
          readOnly={readOnly}
          disabled={disabled}
        >
          {bool_options.map(({ id, value, name }) => (
            <MenuItem key={id} value={value}>
              {t(name)}
            </MenuItem>
          ))}
        </Field>
      )

    case SELECT:
      return (
        <Field
          name={name}
          as={BaseTextField}
          select
          label={name}
          error={!!(touched[name] && errors[name])}
          helperText={touched[name] && t(errors[name])}
          readOnly={readOnly}
          disabled={disabled}
        >
          {options?.map(option => (
            <MenuItem key={option} value={option}>
              {t(option)}
            </MenuItem>
          ))}
        </Field>
      )

    case MULTI_SELECT:
      return (
        <Field
          name={name}
          as={BaseTextField}
          select
          SelectProps={{
            multiple: true
          }}
          label={name}
          error={!!(touched[name] && errors[name])}
          helperText={touched[name] && t(errors[name])}
          value={values[name] || []}
          readOnly={readOnly}
          disabled={disabled}
        >
          {options?.map(option => (
            <MenuItem key={option} value={option}>
              {t(option)}
            </MenuItem>
          ))}
        </Field>
      )

    case PHOTO:
      return (
        <Field name={name}>
          {({ field: { value } }) => {
            return (
              <FileUploadPreview
                name={name}
                readOnly={readOnly}
                withTitle={true}
                onFileChange={async ({
                  target: {
                    validity,
                    files: [file]
                  }
                }) => {
                  if (validity.valid && file) {
                    const response = await upload({
                      url: 'upload/photo',
                      file,
                      type: CUSTOM_PHOTO_TYPE
                    })
                    setFieldValue(name, response)
                  }
                }}
                file={value?.hash}
                loading={loading}
                error={touched[name] && t(errors[name]?.hash)}
              />
            )
          }}
        </Field>
      )

    default:
      return <div>default</div>
  }
}

FieldsFactory.propTypes = {
  isDefault: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.oneOf([NUMBER, STRING, BOOLEAN, SELECT, MULTI_SELECT, PHOTO]),
  options: PropTypes.arrayOf(PropTypes.string),
  formikProps: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  formData: PropTypes.object,
  loadingFormData: PropTypes.bool
}

export default FieldsFactory
