import { useCallback, useReducer } from 'react'
import Compressor from 'compressorjs'

import { fetchUtils } from 'utils'
import { constants } from 'config'

const { MAX_IMAGE_QUALITY } = constants

const compressImage = ({ file }) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: MAX_IMAGE_QUALITY,
      success(result) {
        resolve(result)
      },
      error(err) {
        reject(err)
      }
    })
  })
}

const useFileUpload = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: false,
      error: null,
      data: null
    }
  )

  const upload = useCallback(
    async ({ url, file, type = null, key = null, throwError = false }) => {
      try {
        setState({
          loading: true,
          error: null
        })
        const compressed = await compressImage({ file })
        const formData = new FormData()
        formData.append('photo', compressed)
        if (type) {
          formData.append('type', type)
        }
        const data = await fetchUtils.post(url, formData, true)

        let photoResult
        if (!key) {
          photoResult = {
            ...data,
            original_hash: null
          }
        } else {
          photoResult = {
            ...data,
            [key]: {
              ...data[key],
              original_hash: null
            }
          }
        }

        setState({
          data: {
            ...photoResult
          },
          loading: false
        })

        return photoResult
      } catch (error) {
        setState({
          error,
          loading: false
        })

        if (throwError) {
          throw new Error(error)
        }
      }
    },
    []
  )

  return {
    ...state,
    upload
  }
}

export default useFileUpload
