import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
  from
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { TokenRefreshLink } from 'apollo-link-token-refresh'

import { authUtils, fetchUtils } from 'utils'
import hasuraConfig from '../hasura'
import constants from '../constants'

const { ACCESS_TOKEN_FIELD } = constants

const generateApolloClient = () => {
  const httpLink = new HttpLink({
    uri: hasuraConfig.httpLinkUrl,
    credentials: 'same-origin'
  })

  const wsLink = new WebSocketLink({
    uri: hasuraConfig.wsLinkUrl,
    options: {
      reconnect: true,
      connectionParams: () => {
        return {
          headers: {
            Authorization: authUtils.getAccessToken()
          }
        }
      },
      connectionCallback: error => {
        if (error) {
          wsLink.subscriptionClient.close()
        }
      }
    }
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: authUtils.getAccessToken()
      }
    }
  })

  const refreshTokenLink = new TokenRefreshLink({
    accessTokenField: ACCESS_TOKEN_FIELD,
    isTokenValidOrUndefined: authUtils.isTokenValidOrUndefined,
    fetchAccessToken: () =>
      fetchUtils.get('auth/refresh-token', {
        refresh_token: authUtils.getRefreshToken()
      }),
    handleResponse: () => response => {
      authUtils.setTokens(response)
      return response
    },
    handleError: () => {
      authUtils.cleanTokens()
      window.location.replace('/')
    }
  })

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    httpLink
  )

  return new ApolloClient({
    link: from([refreshTokenLink, authLink, link]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    },
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            ip_camera_by_pk(existing, { canRead, args: { id }, toReference }) {
              return canRead(existing)
                ? existing
                : toReference({ __typename: 'ip_camera', id })
            },
            user_by_pk(existing, { canRead, args: { id }, toReference }) {
              return canRead(existing)
                ? existing
                : toReference({ __typename: 'user', id })
            },
            organization_by_pk(
              existing,
              { canRead, args: { id }, toReference }
            ) {
              return canRead(existing)
                ? existing
                : toReference({ __typename: 'organization', id })
            },
            location_by_pk(existing, { canRead, args: { id }, toReference }) {
              return canRead(existing)
                ? existing
                : toReference({ __typename: 'location', id })
            },
            release_by_pk(existing, { canRead, args: { id }, toReference }) {
              return canRead(existing)
                ? existing
                : toReference({ __typename: 'release', id })
            },
            edi_template_by_pk(
              existing,
              { canRead, args: { id }, toReference }
            ) {
              return canRead(existing)
                ? existing
                : toReference({ __typename: 'edi_template', id })
            },
            transaction_template_by_pk(
              existing,
              { canRead, args: { id }, toReference }
            ) {
              return canRead(existing)
                ? existing
                : toReference({ __typename: 'transaction_template', id })
            }
          }
        }
      }
    })
  })
}

export default generateApolloClient
