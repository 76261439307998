import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Box, TextField } from '@mui/material'
import {
  DataGridPro,
  getGridDateOperators,
  getGridStringOperators
} from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'

import { xGridUtils } from 'utils'

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  [`& .MuiDataGrid-columnHeader`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  }
}))

export const initialWidthLarge = 180
export const initialWidthSmall = 120
export const initialPage = 0
export const dateFilterOperators = getGridDateOperators().filter(({ value }) =>
  ['onOrBefore', 'onOrAfter'].includes(value)
)
export const jsonFilterOperators = getGridStringOperators().filter(
  ({ value }) => ['contains'].includes(value)
)
export const enumOperators = getGridStringOperators().filter(({ value }) =>
  ['equals'].includes(value)
)

export function EnumInputComponent({ item, applyValue, children }) {
  const { t } = useTranslation()
  const handleFilterChange = event => {
    applyValue({ ...item, value: event.target.value })
  }

  return (
    <TextField
      select
      variant='standard'
      SelectProps={{
        native: true
      }}
      label={t('value')}
      onChange={handleFilterChange}
      value={item.value}
    >
      {children}
    </TextField>
  )
}

function BaseXGrid({ height = '85vh', width = 'auto', ...props }) {
  const { t } = useTranslation()

  const localeText = xGridUtils.getLocales(t)

  return (
    <Box display='flex' height={height} width={width}>
      <StyledDataGridPro
        rowsPerPageOptions={[10, 50, 100, 200, 500, 1000]}
        disableSelectionOnClick={true}
        disableColumnMenu={true}
        showCellRightBorder={true}
        localeText={localeText}
        columnTypes={{
          enum: {
            extendType: 'string',
            filterOperators: enumOperators
          },
          json: {
            extendType: 'string',
            filterOperators: jsonFilterOperators
          }
        }}
        {...props}
      />
    </Box>
  )
}

BaseXGrid.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  props: PropTypes.object
}

EnumInputComponent.propTypes = {
  item: PropTypes.object.isRequired,
  applyValue: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired
}

export default BaseXGrid
