import React, { useState, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  MenuItem,
  Typography
} from '@mui/material'
import {
  AccountCircle as AccountCircleIcon,
  Menu as MenuIcon
} from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { useAuth } from 'context/auth/auth'
import { Logo, Link, BaseMenu } from 'components'
import routes from '../shared/routes'
import { drawerWidth } from '../'

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

function AppBarComponent({ handleDrawerOpen, open }) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { logout } = useAuth()
  const history = useHistory()
  const { pathname } = useLocation()
  const [anchorEl, setAnchorEl] = useState(null)
  const routeKey = Object.keys(routes).find(route => {
    const { path } = routes[route]
    return pathname.startsWith(path)
  })

  const handleAccountClick = useCallback(event => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleAccountClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleShowProfile = useCallback(() => {
    history.push('/profile')
    handleAccountClose()
  }, [history, handleAccountClose])

  return (
    <StyledAppBar position='fixed' open={open}>
      <Toolbar>
        <Box
          display='flex'
          flex={1}
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box display='flex' flexDirection='row' alignItems='center'>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
              size='large'
            >
              <MenuIcon />
            </IconButton>

            <Link to='/'>
              <Logo height={theme.mixins.toolbar.minHeight} />
            </Link>

            <Typography variant='subtitle1' component='div'>
              <Box ml={3}>{t(routeKey)}</Box>
            </Typography>
          </Box>

          <>
            <AccountCircleIcon onClick={handleAccountClick} />
            <BaseMenu
              id='profile-menu'
              anchorEl={anchorEl}
              keepMounted
              open={!!anchorEl}
              onClose={handleAccountClose}
            >
              <MenuItem onClick={handleShowProfile}>{t('profile')}</MenuItem>
              <MenuItem onClick={logout}>{t('logout')}</MenuItem>
            </BaseMenu>
          </>
        </Box>
      </Toolbar>
    </StyledAppBar>
  )
}

AppBarComponent.propTypes = {
  handleDrawerOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default AppBarComponent
