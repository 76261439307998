import { useCallback } from 'react'

import { constants } from 'config'

const {
  LOCAL_STORAGE_KEYS: { TABLE_PREFERENCES, TRANSACTION_TABLE_PREFERENCES }
} = constants

function useTablePreferences(
  globalLocalStorageKey = TRANSACTION_TABLE_PREFERENCES
) {
  const getPreferences = useCallback(() => {
    const preferences = window.localStorage.getItem(TABLE_PREFERENCES)
    return preferences ? JSON.parse(preferences) : {}
  }, [])

  const getTablePreferences = useCallback(
    (localStorageKey = globalLocalStorageKey) => {
      const preferences = getPreferences()
      const tablePreferences = preferences?.[localStorageKey]

      return tablePreferences || {}
    },
    [getPreferences, globalLocalStorageKey]
  )

  const setTablePreferences = useCallback(
    (preferences, localStorageKey = globalLocalStorageKey) => {
      const currentPreferences = getPreferences()
      const currentTablePreferences = getTablePreferences(localStorageKey)

      window.localStorage.setItem(
        TABLE_PREFERENCES,
        JSON.stringify({
          ...currentPreferences,
          [localStorageKey]: {
            ...currentTablePreferences,
            ...preferences
          }
        })
      )
    },
    [getPreferences, getTablePreferences, globalLocalStorageKey]
  )

  const getIfColumnIsHidden = useCallback(
    (fieldName, localStorageKey = globalLocalStorageKey) => {
      const tablePreferences = getTablePreferences(localStorageKey)

      return (
        tablePreferences?.visibleColumns &&
        !tablePreferences?.visibleColumns?.includes(fieldName)
      )
    },
    [getTablePreferences, globalLocalStorageKey]
  )

  const getDensity = useCallback(
    (localStorageKey = globalLocalStorageKey) => {
      const tablePreferences = getTablePreferences(localStorageKey)
      return tablePreferences?.density
    },
    [getTablePreferences, globalLocalStorageKey]
  )

  const getPinnedColumns = useCallback(
    (localStorageKey = globalLocalStorageKey) => {
      const tablePreferences = getTablePreferences(localStorageKey)
      return tablePreferences?.pinnedColumns
    },
    [getTablePreferences, globalLocalStorageKey]
  )

  return {
    getPreferences,
    getTablePreferences,
    setTablePreferences,
    getIfColumnIsHidden,
    getDensity,
    getPinnedColumns
  }
}

export default useTablePreferences
