import { photoUtils, templateUtils } from 'utils'
import { constants } from 'config'

const { formatPhotosObject } = photoUtils
const {
  DEFAULT_FIELDS: {
    CONTAINER_YEAR,
    LINER_ID,
    SHIPPER_ID,
    CONSIGNEE_ID,
    IS_DAMAGE,
    DAMAGE_PHOTOS,
    PTI_PASS,
    CLEAN
  },
  REQUIRED_FIELDS,
  FIELD_TYPES: { BOOLEAN }
} = constants

const formatFormValues = object => {
  const objectCopy = { ...object }
  const photos = formatPhotosObject(object.photos)
  const {
    container_number,
    container_iso_type,
    container_year,
    max_payload_kilograms,
    mgw_kilograms,
    tare_kilograms,
    volume_cbm
  } = object.inventory_item
  const customFields = object.custom_fields
  delete objectCopy.inventory_item
  delete objectCopy.custom_fields

  const response = {
    ...objectCopy,
    ...photos,
    cargo_weight_kilograms: objectCopy.cargo_weight_kilograms || '',
    comments: objectCopy.comments || '',
    container_number,
    container_iso_type,
    container_year,
    max_payload_kilograms,
    mgw_kilograms,
    tare_kilograms,
    volume_cbm,
    transaction_id: objectCopy.id,
    ...customFields
  }

  return response
}

const formatUpdateValues = transaction => {
  const transactionTemplate = transaction?.transaction_template
  const template = transactionTemplate?.template
  const trx = {
    transaction_id: transaction?.id,
    uuid: transaction?.uuid
  }

  if (!template || !transaction) {
    return trx
  }

  const is_damage = !!(
    transaction?.is_damage === true || transaction?.is_damage === 'true'
  )

  Object.values(REQUIRED_FIELDS).forEach(name => {
    const value = transaction[name]

    trx[name] = name === REQUIRED_FIELDS.CLIENT_ID ? parseInt(value) : value
  })

  Object.keys(template).forEach(key => {
    const step = template[key]
    const { fields } = step
    const sortedFields = templateUtils.sortByPosition(fields)
    const fieldKeys = Object.keys(sortedFields)

    fieldKeys.forEach(key => {
      const { isDefault, name, type } = sortedFields[key]
      const value = transaction[name] || null

      if (isDefault) {
        switch (key) {
          case LINER_ID:
          case SHIPPER_ID:
          case CONSIGNEE_ID:
          case CONTAINER_YEAR:
            trx[name] = value ? parseInt(value) : value
            break

          case IS_DAMAGE:
          case PTI_PASS:
          case CLEAN:
            trx[name] = value === true || value === 'true'
            break

          case DAMAGE_PHOTOS:
            trx[name] = is_damage ? value : null
            break

          default:
            trx[name] = value
            break
        }
      } else {
        if (!trx.custom_fields) {
          trx.custom_fields = {}
        }

        switch (type) {
          case BOOLEAN:
            trx.custom_fields[name] = value === true || value === 'true'
            break

          default:
            trx.custom_fields[name] = value
            break
        }
      }
    })
  })

  return {
    variables: {
      transaction: trx
    }
  }
}

export default {
  formatFormValues,
  formatUpdateValues
}
