import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { transactionApi } from 'api'
import { QueryError, ListSkeleton, ImageZoom } from 'components'
import { ipfsConfig } from 'config'

function TransactionDetailPanel({ transactionId }) {
  const { t } = useTranslation()
  const { data, loading, error, refetch } = useQuery(
    transactionApi.GET_TRANSACTION_PHOTOS,
    {
      variables: {
        transactionId
      }
    }
  )

  const handleRefetchQuery = useCallback(() => {
    refetch()
  }, [refetch])

  if (error) {
    return (
      <QueryError
        fullWidth={false}
        onClick={handleRefetchQuery}
        disabled={loading}
        error={error}
      />
    )
  }

  return (
    <Box p={2}>
      <Typography variant='subtitle1'>{t('photos')}</Typography>
      {loading ? (
        <ListSkeleton length={5} height={200} width={300} />
      ) : (
        <Box display='flex' flexWrap='wrap'>
          {data?.photos?.map(photo => {
            return (
              <Box key={photo?.hash} m={2}>
                <Typography>{t(photo.type)}</Typography>

                <ImageZoom
                  src={`${ipfsConfig.url}${photo?.hash}`}
                  alt={`${photo?.hash}`}
                  height={200}
                />

                {photo?.damages?.map(damage => {
                  return (
                    <Typography key={damage?.id}>{t(damage?.type)}</Typography>
                  )
                })}

                {photo?.seals?.map(seal => {
                  return (
                    <Typography key={seal?.id}>
                      {`${t(seal?.type)} - ${seal?.value}`}
                    </Typography>
                  )
                })}
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

TransactionDetailPanel.propTypes = {
  transactionId: PropTypes.number
}

export default TransactionDetailPanel
