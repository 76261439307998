import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  name: '',
  client_id: '',
  client_code: '',
  locations: [
    {
      location_id: '',
      location_code: ''
    }
  ],
  liner_codes: [
    {
      maersk_liner_code_id: '',
      smartgate_liner_code_id: ''
    }
  ],
  seal_codes: [
    {
      maersk_seal_code_id: '',
      smartgate_seal_code: ''
    }
  ]
}

const schema = yup.object().shape({
  name: yup.string().required(validations.requiredField.code),
  client_id: yup.string().required(validations.requiredField.code),
  client_code: yup.string().required(validations.requiredField.code),
  locations: yup
    .array()
    .of(
      yup.object().shape({
        location_id: yup.string().required(validations.requiredField.code),
        location_code: yup.string().required(validations.requiredField.code)
      })
    )
    .required(validations.requiredField.code),
  liner_codes: yup
    .array()
    .of(
      yup.object().shape({
        maersk_liner_code_id: yup
          .string()
          .required(validations.requiredField.code),
        smartgate_liner_code_id: yup
          .string()
          .required(validations.requiredField.code)
      })
    )
    .required(validations.requiredField.code),
  seal_codes: yup
    .array()
    .of(
      yup.object().shape({
        maersk_seal_code_id: yup
          .string()
          .required(validations.requiredField.code),
        smartgate_seal_code: yup
          .string()
          .required(validations.requiredField.code)
      })
    )
    .required(validations.requiredField.code)
})

export default {
  initialValues,
  schema
}
