import { gql } from '@apollo/client'

const CAMERA_FRAGMENT = gql`
  fragment CameraFragment on ip_camera {
    id
    name
    address
    port
    username
    password
    location_id
    created_at
    location {
      id
      name
    }
  }
`

const GET_FORM_DATA = gql`
  query cameraFormData {
    locations: location(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`

const GET_CAMERAS = gql`
  query cameras(
    $limit: Int
    $offset: Int
    $orderBy: [ip_camera_order_by!]
    $where: ip_camera_bool_exp
  ) {
    camerasAggregate: ip_camera_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    cameras: ip_camera(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      ...CameraFragment
    }
  }
  ${CAMERA_FRAGMENT}
`

const GET_CAMERA = gql`
  query camera($id: Int!) {
    camera: ip_camera_by_pk(id: $id) {
      ...CameraFragment
    }
  }
  ${CAMERA_FRAGMENT}
`

const UPDATE_CAMERA = gql`
  mutation updateCamera(
    $id: Int!
    $name: String
    $address: String
    $port: Int
    $username: String
    $password: String
    $location_id: Int
  ) {
    camera: update_ip_camera_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        address: $address
        port: $port
        username: $username
        password: $password
        location_id: $location_id
      }
    ) {
      ...CameraFragment
    }
  }
  ${CAMERA_FRAGMENT}
`

const DELETE_CAMERA = gql`
  mutation deleteCamera($id: Int!) {
    camera: update_ip_camera_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
  }
`

const CREATE_CAMERA = gql`
  mutation insertCamera($object: ip_camera_insert_input!) {
    camera: insert_ip_camera_one(object: $object) {
      ...CameraFragment
    }
  }
  ${CAMERA_FRAGMENT}
`

export default {
  GET_FORM_DATA,
  GET_CAMERAS,
  GET_CAMERA,
  UPDATE_CAMERA,
  DELETE_CAMERA,
  CREATE_CAMERA
}
