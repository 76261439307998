import { useCallback } from 'react'

import { constants } from 'config'

const {
  LOCAL_STORAGE_KEYS: { CHARTS_PREFERENCES },
  CHARTS
} = constants
const chartKeys = Object.keys(CHARTS)
const { MONTHLY_TRANSACTIONS } = CHARTS

function useChartPreferences({
  locationId: globalLocationId,
  chart: globalChart = MONTHLY_TRANSACTIONS
}) {
  const getPreferences = useCallback(() => {
    const preferences = window.localStorage.getItem(CHARTS_PREFERENCES)
    return preferences ? JSON.parse(preferences) : {}
  }, [])

  const getLocationPreferences = useCallback(
    (locationId = globalLocationId) => {
      const preferences = getPreferences()

      return preferences?.[locationId] || {}
    },
    [getPreferences, globalLocationId]
  )

  const getArrayOfVisibleCharts = useCallback(
    (locationId = globalLocationId) => {
      const locationPreferences = getLocationPreferences(locationId)
      const preferencesChartKeys = Object.keys(locationPreferences)
      const arrayOfVisibleCharts = []

      if (!preferencesChartKeys?.length) {
        return chartKeys
      }

      for (const chartKey of preferencesChartKeys) {
        const isHidden = locationPreferences?.[chartKey]?.hidden

        if (!isHidden) {
          arrayOfVisibleCharts.push(chartKey)
        }
      }

      return arrayOfVisibleCharts
    },
    [getLocationPreferences, globalLocationId]
  )

  const getChartPreferences = useCallback(
    ({ locationId = globalLocationId, chart = globalChart }) => {
      const locationPreferences = getLocationPreferences(locationId)

      const chartPreferences = locationPreferences?.[chart]

      return chartPreferences || {}
    },
    [getLocationPreferences, globalChart, globalLocationId]
  )

  const setChartPreferences = useCallback(
    ({ locationId = globalLocationId, preferences, chart = globalChart }) => {
      const currentPreferences = getPreferences()
      const currentLocationPreferences = getLocationPreferences(locationId)
      const currentChartPreferences = getChartPreferences({ locationId, chart })

      window.localStorage.setItem(
        CHARTS_PREFERENCES,
        JSON.stringify({
          ...currentPreferences,
          [locationId]: {
            ...currentLocationPreferences,
            [chart]: {
              ...currentChartPreferences,
              ...preferences
            }
          }
        })
      )
    },
    [
      getPreferences,
      getLocationPreferences,
      getChartPreferences,
      globalLocationId,
      globalChart
    ]
  )

  const getIfChartIsHidden = useCallback(
    ({ locationId = globalLocationId, chart = globalChart }) => {
      const chartPreferences = getChartPreferences({ locationId, chart })

      return chartPreferences?.hidden
    },
    [getChartPreferences, globalLocationId, globalChart]
  )

  const getIfChartIsVisible = useCallback(
    ({ locationId = globalLocationId, chart = globalChart }) => {
      const chartPreferences = getChartPreferences({ locationId, chart })

      return chartPreferences?.hidden !== true
    },
    [getChartPreferences, globalLocationId, globalChart]
  )

  return {
    getPreferences,
    getLocationPreferences,
    getArrayOfVisibleCharts,
    getChartPreferences,
    setChartPreferences,
    getIfChartIsHidden,
    getIfChartIsVisible
  }
}

export default useChartPreferences
