import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  name: '',
  latlong: ''
}

const schema = yup.object().shape({
  name: yup.string().required(validations.requiredField.code),
  latlong: yup.string().required(validations.requiredField.code)
})

export default {
  initialValues,
  schema
}
