import { arrayUtils, typesUtils } from 'utils'

const createFormat = ({
  name,
  client_id,
  release_party,
  expiration,
  location_id,
  to_location_code,
  un_locode
}) => {
  return {
    variables: {
      object: {
        name,
        client_id,
        release_party,
        expiration,
        location_id,
        to_location_code: to_location_code || null,
        un_locode: un_locode || null
      }
    }
  }
}

const updateInvetoryItems = (release_id, transactions) => {
  return {
    variables: {
      release_id,
      inventory_items: transactions.map(({ inventory_item: { id } }) => id)
    }
  }
}

const updateFormat = (
  {
    id,
    name,
    release_party,
    expiration,
    approved,
    to_location_code,
    un_locode,
    transactions
  },
  release,
  isOrganizationViewer
) => {
  const originalInventoryItemIds = release.inventory_items
    .filter(({ transaction: { type } }) => typesUtils.isGateIn(type))
    .map(({ id }) => id)
  const currentInventoryItemIds = transactions.map(
    ({ inventory_item: { id } }) => id
  )

  const deleteInventoryItemIds = arrayUtils.diff(
    originalInventoryItemIds,
    currentInventoryItemIds
  )

  const insertInventoryItemIds = arrayUtils.diff(
    currentInventoryItemIds,
    originalInventoryItemIds
  )

  return {
    variables: isOrganizationViewer
      ? {
          id,
          name,
          release_party,
          expiration,
          to_location_code: to_location_code || null,
          un_locode: un_locode || null,
          deleteInventoryItemIds,
          insertInventoryItemIds
        }
      : {
          id,
          name,
          release_party,
          expiration,
          approved,
          to_location_code: to_location_code || null,
          un_locode: un_locode || null,
          deleteInventoryItemIds,
          insertInventoryItemIds
        }
  }
}

const deleteFormat = id => {
  return {
    variables: {
      id
    }
  }
}

export default {
  createFormat,
  updateInvetoryItems,
  updateFormat,
  deleteFormat
}
