import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

function useSizes() {
  const theme = useTheme()

  const xsDown = useMediaQuery(theme.breakpoints.down('sm'))
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('lg'))
  const xlDown = useMediaQuery(theme.breakpoints.down('xl'))

  return {
    xsDown,
    smUp,
    mdDown,
    xlDown
  }
}

export default useSizes
